import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import AppProvider from '../../context/AppContext'
import MediaQueryDebug from '../common/MediaQueryDebug'
import GlobalStyle from '../../style/global'
import { GATSBY_SITE_URL } from '../../../lib/constants'
import styled, { css, ThemeProvider } from 'styled-components'
import theme from '../../style/theme'
import { get } from 'lodash'
import Transition from '../common/Transition'
import useParseSiteData from '../../hooks/useParseSiteData'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import wait from '../../utils/wait'
import AddThisOptions from '@awolf81/gatsby-theme-addthis/components/AddThisOptions'
import SplashLoader from '../common/SplashLoader'

const pollForHasLoaded = async setHasLoaded => {
  while (!window.___HAS_LOADED) {
    await wait(500)
  }

  await wait(2500)

  setHasLoaded(true)
}

const Layout = ({ children, pageContext, location }, ...rest) => {
  const { pagePath, staticData: staticDataJson } = pageContext
  const staticData =
    typeof staticDataJson === 'string'
      ? JSON.parse(staticDataJson)
      : staticDataJson
  const googlaAnalyticsCode = get(
    staticData,
    'sharedGlobalProperties.google_analytics_code',
    '',
  )

  const googleGTMCodes = get(
    staticData,
    'sharedGlobalProperties.google_gtm_codes',
    '',
  )

  const defaultTitle = get(staticData, 'sharedGlobalProperties.site_title', '')

  const siteTitle = get(staticData, 'sharedGlobalProperties.site_title', '')
  const fbAppId = get(staticData, 'sharedGlobalProperties.facebook_app_id', '')

  const fbAdPixel = get(
    staticData,
    'sharedGlobalProperties.facebook_ad_pixel',
    '',
  )

  const twitter = get(staticData, 'sharedGlobalProperties.twitter_handle', '')
  const shareImage = get(
    staticData,
    'sharedGlobalProperties.global_share_image',
    {},
  )

  const description = get(staticData, 'data.seo_page_description', '')
  const overrideShareImage = get(staticData, 'data.seo_page_override_image', {})
  const pageTitle = get(staticData, 'data.seo_page_title') || defaultTitle

  const {
    sections: { header, footer },
  } = useParseSiteData(staticData)

  useEffect(() => {
    if (typeof window.ga === 'function') {
      window.ga('send', 'pageview', location.pathname, {
        location: location.href,
        page: location.pathname,
        title: pageTitle,
      })
    }

    if (
      typeof window.addthis === 'object' &&
      window.addthis.layers &&
      window.addthis.layers.refresh
    ) {
      window.addthis.layers.refresh()
    }
  }, [location.href, location.pathname, pageTitle])

  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    pollForHasLoaded(setHasLoaded)
  }, [])

  const canonical =
    `${GATSBY_SITE_URL}` + `/${pagePath}`.replace(/\/{2,}/g, '/')
  const image = overrideShareImage.url ? overrideShareImage : shareImage
  if (!image.dimensions) image.dimensions = { width: 1024, height: 512 }

  const address = get(footer, 'address')

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=2.0, user-scalable=yes"
        />
        <meta content={siteTitle} name="apple-mobile-web-app-title" />
        <meta content={pageTitle} property="og:title" />
        <meta content={pageTitle} name="twitter:title" />
        <title>{pageTitle}</title>

        <meta content={description} name="description" />
        <meta content="Rush Street Gaming" name="author" />
        <meta content={description} property="og:description" />
        <meta content={description} name="twitter:description" />

        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          content="black-translucent"
          name="apple-mobile-web-app-status-bar-style"
        />
        <meta content={theme.color.blue} name="theme-color" />
        <meta content={siteTitle} name="application-name" />

        <meta content="website" property="og:type" />
        <meta content={siteTitle} property="og:site_name" />
        <meta content={fbAppId} property="fb:app_id" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={`@${twitter}`} name="twitter:site" />
        <meta content={`@${twitter}`} name="twitter:creator" />
        <meta content={pageTitle} name="twitter:text:title" />
        <meta content={canonical} property="og:url" />
        <meta content={canonical} name="twitter:url" />
        <link rel="canonical" href={canonical} />

        <meta content={image.url} property="og:image" />
        <meta content={image.dimensions.width} property="og:image:width" />
        <meta content={image.dimensions.height} property="og:image:height" />
        <meta content={image.url} name="twitter:image" />
        <meta content={image.dimensions.width} name="twitter:image:width" />
        <meta content={image.dimensions.height} name="twitter:image:height" />
        <meta content={image.url} property="og:image" />
        <meta content={image.dimensions.width} property="og:image:width" />
        <meta content={image.dimensions.height} property="og:image:height" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cloud.typography.com/6398716/7041612/css/fonts.css"
        />
        {/* Start cookieyes banner */}
        <script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/363ff6b1d0f845d6f8c969c4/script.js"
        ></script>
        {/* End cookieyes banner */}
        {fbAdPixel && (
          <script>{`
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${fbAdPixel}'); // Insert your pixel ID here.
          fbq('track', 'PageView');
        `}</script>
        )}
        {googlaAnalyticsCode && (
          <script>{`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', '${googlaAnalyticsCode}', 'auto');
          `}</script>
        )}
        {googleGTMCodes &&
          !!googleGTMCodes.length &&
          googleGTMCodes.map(({ gtm_code }) => (
            <script
              key={`gtm_js_${gtm_code}`}
            >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtm_code}');
          `}</script>
          ))}

        <script type="text/javascript">
          {`
            var addthis_share = {
                title: "${pageTitle}",
                passthrough : {
                    twitter: {
                        via: "${twitter}"
                    }
                },
                description: "${description}"
            };
            var addthis_config = {
                ui_email_note: "${description}"
            };
        `}
        </script>
      </Helmet>
      {fbAdPixel && (
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${fbAdPixel}&ev=PageView&noscript=1`}
          />
        </noscript>
      )}
      {googleGTMCodes &&
        !!googleGTMCodes.length &&
        googleGTMCodes.map(({ gtm_code }) => (
          <noscript key={`gtm_${gtm_code}`}>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${gtm_code}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
        ))}
      <AppProvider>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle loaded={hasLoaded} />
            <Content>
              <div id="loading-screen">
                <SplashLoader />
                {/*<Loader type="Oval" color={theme.color.blue} height={50} width={50} />*/}
              </div>
              <Header {...header} address={address} />
              <Transition location={location}>{children}</Transition>
              <Footer {...footer} />
            </Content>
            <MediaQueryDebug />
          </>
        </ThemeProvider>
      </AppProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default Layout

const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    min-height: 100vh;
    main {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  `};
`
