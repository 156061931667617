import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ShortResults from './ShortResults'
import { useAppContext } from '../../../context/AppContext'

const Portal = ({ children, id }) =>
  createPortal(children, document.getElementById(id))

Portal.propTypes = {
  children: PropTypes.any,
  id: PropTypes.any,
}

const Results = ({ newInput, results, search, setShowSearch, status }) => {
  const {
    setCurrentSearch,
    setCurrentSearchResults,
    setShowAllSearchResults,
    isXLarge,
  } = useAppContext()

  const toggleShowAll = useCallback(() => {
    setCurrentSearch(search)
    setCurrentSearchResults(results)
    setShowAllSearchResults(true)
    setShowSearch(false)
  }, [
    results,
    search,
    setCurrentSearch,
    setCurrentSearchResults,
    setShowAllSearchResults,
    setShowSearch,
  ])

  const portalId = isXLarge
    ? 'hint-results-portal'
    : 'hint-results-portal-mobile'

  return (
    <>
      {!!search.length && (
        <Portal id={portalId}>
          <Background>
            <ShortResults
              results={results}
              toggleShowAll={toggleShowAll}
              status={status}
              newInput={newInput}
              setShowSearch={setShowSearch}
            />
          </Background>
        </Portal>
      )}
    </>
  )
}

Results.propTypes = {
  results: PropTypes.array,
  search: PropTypes.string,
  setShowSearch: PropTypes.func,
  status: PropTypes.string,
}

export default Results

const Background = styled.div`
  ${({ theme }) => css`
    ${theme.mixin.fadeIn(0, 0.25)};
    position: relative;
    background: ${theme.color.softBlue};
    height: 150vh;
  `}
`
