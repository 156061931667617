import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '../../common/Button'
import Link from '../../common/Link'
import PlusMinus from '../../common/PlusMinus'
import Properties from './PropertiesSmall'
import Text, { setFontSize } from '../../common/Text'
import { motion, AnimatePresence } from 'framer-motion'
import ReactHtmlParser from 'react-html-parser'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import { transformParagraphToSpan } from '../../../utils/parserTransforms'
import { color } from '../../../style/theme'
import { useAppContext } from '../../../context/AppContext'
import linkResolver from '../../../utils/linkResolver'

const ITEM_HEIGHT = 60

const MenuItem = ({
  activeIndex,
  closeModal,
  index,
  setIndex,
  primary,
  items,
}) => {
  const { focusStyleOn } = useAppContext()
  const itemRef = useRef(null)
  const itemOpen = activeIndex === index
  const label = get(primary, 'label')
  const itemLink = {
    href: linkResolver(primary, 'link'),
    target: get(primary, 'link.target'),
  }
  const handleClick = () => {
    setIndex(itemOpen ? null : index)
    //if (!itemOpen) itemRef.current.scrollIntoView()
  }

  return (
    <Item ref={itemRef}>
      <Hit onClick={handleClick} active={itemOpen} />
      <RowButton
        aria-label={label}
        onClick={handleClick}
        tabIndex={0}
        showFocus={focusStyleOn}
      >
        {label && (
          <Text
            white={itemOpen}
            size="lg"
            medium
            uppercase
            nowrap
            lineHeightMultiplier={1.72}
            spacing={1.3}
            color="blue"
          >
            {label}
          </Text>
        )}
        <PlusMinus
          colorOff="#6AAAE4"
          colorOn={color.secondaryLightBlue}
          itemOpen={itemOpen}
          width={14}
          height={14}
        />
      </RowButton>
      <AnimatePresence initial={false}>
        {itemOpen && (
          <List
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            <div key={label}>
              <Link {...itemLink} onClick={closeModal}>
                <Text
                  medium
                  uppercase
                  size={14}
                  lineHeight="35px"
                  color="accentBlue"
                  spacing={1.1}
                >
                  View All
                </Text>
              </Link>
            </div>
            {items &&
              items.map((item, i) => {
                const label = get(item, 'label')
                const link = {
                  href: linkResolver(item, 'link'),
                  target: get(item, 'link.target'),
                }

                return (
                  <div key={label + i}>
                    <Link {...link} onClick={closeModal}>
                      <Text
                        html
                        medium
                        uppercase
                        size={14}
                        lineHeight="35px"
                        spacing={1.1}
                        color="blue"
                      >
                        {label}
                      </Text>
                    </Link>
                  </div>
                )
              })}
          </List>
        )}
      </AnimatePresence>
    </Item>
  )
}

MenuItem.propTypes = {
  activeIndex: PropTypes.number,
  closeModal: PropTypes.func,
  index: PropTypes.number,
  items: PropTypes.array,
  setIndex: PropTypes.func,
  primary: PropTypes.object,
}

const Menu = ({ closeModal, address, links, properties, topNavBar }) => {
  const { propertiesModalOpen } = useAppContext()
  const [activeIndex, setIndex] = useState(null)
  const topBarLinks = get(topNavBar, 'links')

  return (
    <Container>
      {!propertiesModalOpen && (
        <>
          {topBarLinks && (
            <NavLinks>
              {topBarLinks.map((item, i) => {
                const text = get(item, 'label.html')
                const link = {
                  href: linkResolver(item, 'link'),
                  target: get(item, 'link.target'),
                }
                return text ? (
                  <Link key={`left${i}`} {...link} onClick={closeModal}>
                    <Button underline tabIndex={-1}>
                      {ReactHtmlParser(text, {
                        transform: transformParagraphToSpan,
                      })}
                    </Button>
                  </Link>
                ) : null
              })}
            </NavLinks>
          )}

          {links && (
            <SiteLinks>
              {links.map((item, i) => {
                return (
                  <MenuItem
                    {...item}
                    closeModal={closeModal}
                    activeIndex={activeIndex}
                    key={`sl${i}`}
                    index={i}
                    setIndex={setIndex}
                  />
                )
              })}
            </SiteLinks>
          )}
        </>
      )}

      <Properties
        address={address}
        backLabel={get(properties, 'back_label')}
        hereLabel={get(properties, 'here_label')}
        label={get(properties, 'label')}
        list={get(properties, 'properties') || []}
      />
    </Container>
  )
}

Menu.propTypes = {
  closeModal: PropTypes.func,
  links: PropTypes.array,
  properties: PropTypes.object,
  topNavBar: PropTypes.object,
  children: PropTypes.node,
}

export default Menu

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;

    > button {
      margin: ${theme.space(1)} auto;
      width: 100%;
      max-width: none;
    }
  `};
`

const NavLinks = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    padding: ${theme.space(2)} 0;
    background-color: #fff;
    border-radius: ${theme.layout.borderRadius};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > a {
      margin-left: ${theme.space(2)};

      &:not(:last-child) {
        margin-bottom: ${theme.space(1.5)};
      }

      > button > div {
        ${setFontSize(13, 1.72)};
      }
    }
  `};
`

const SiteLinks = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 0 ${theme.space(1)} 0;
    border-radius: ${theme.layout.borderRadius};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
  `};
`
const Item = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    background-color: #fff;

    > button {
      width: 100%;
      padding: 0 ${theme.space(2)} 0;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;

        background-color: ${theme.color.softBlue};
      }
    }
  `};
`

const Hit = styled.div`
  ${({ active, theme }) => css`
    position: absolute;
    left: 0;
    top: 0;
    height: ${rem(ITEM_HEIGHT)};
    width: 100%;
    cursor: pointer;
    z-index: 1;
    opacity: ${active ? 1 : 0};

    ${theme.layout.bgGradient};

    transition: opacity 0.5s ease-out;
  `};
`

const RowButton = styled.button`
  ${({ showFocus, theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${rem(ITEM_HEIGHT)};

    cursor: pointer;
    z-index: 2;

    ${theme.mixin.afterFocus};
  `};
`

const List = styled(motion.div)`
  ${({ theme }) => css`
    padding: 0 ${theme.space(1)};

    > div {
      padding: 0 ${theme.space(2)};

      &:first-child {
        padding-top: ${theme.space(2)};
      }

      &:last-child {
        padding-bottom: ${theme.space(2)};
      }
    }
  `};
`
