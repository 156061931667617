import React from 'react'
import PropTypes from 'prop-types'

import Nav from './Nav'
import TopNavBar from './TopNavBar'

import styled, { css } from 'styled-components'
import { rem } from 'polished'

const Header = props => {
  return (
    <Container>
      <TopNavBar {...props} />
      <Nav {...props} />
      <HintResultsContainer id="hint-results" />
    </Container>
  )
}

Header.propTypes = {
  nav: PropTypes.object,
  properties: PropTypes.object,
  topNavBar: PropTypes.object,
}

export default Header

const HintResultsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: absolute;
    z-index: 99999;
  `}
`

const Container = styled.header`
  ${({ theme }) => css`
    position: relative;
    z-index: 999;
    height: ${rem(theme.layout.navHeight.xs)};

    background-color: ${theme.color.softBlue};

    ${theme.media.xl`
      height: ${rem(theme.layout.navHeight.xl)};
    `};
  `};
`
