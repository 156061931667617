const AWS_DEFAULT_REGION = process.env.AWS_DEFAULT_REGION || 'us-east-2'
const NODE_ENV = process.env.NODE_ENV || 'development'
const AWS_S3_BUCKET_NAME_EDGE_REDIRECT =
  process.env.AWS_S3_BUCKET_NAME_EDGE_REDIRECT
const PRISMIC_API_KEY = process.env.PRISMIC_API_KEY
const PRISMIC_REPO_NAME = process.env.GATSBY_PRISMIC_REPO_NAME
const AWS_LAMBDA_BUILD_ARN = process.env.AWS_LAMBDA_BUILD_ARN
const GATSBY_PATH_PREFIX = process.env.GATSBY_PATH_PREFIX || '/'
const GATSBY_SITE_URL = process.env.GATSBY_SITE_URL
const GATSBY_GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
const CURATOR_API_KEY = process.env.CURATOR_API_KEY
const CURATOR_INSTAGRAM_FEED_ID = process.env.CURATOR_INSTAGRAM_FEED_ID
const CURATOR_TWITTER_FEED_ID = process.env.CURATOR_TWITTER_FEED_ID
const CLOUDSEARCH_DOCUMENT_ENDPOINT = process.env.CLOUDSEARCH_DOCUMENT_ENDPOINT
const INDEX_SEARCH = process.env.INDEX_SEARCH === 'true'
const GATSBY_CLOUDSEARCH_SEARCH_ENDPOINT =
  process.env.GATSBY_CLOUDSEARCH_SEARCH_ENDPOINT
const GATSBY_FORM_URL = process.env.GATSBY_FORM_URL

const RULE_PREFIX_SCHEDULE = 'schedule'

// error paths
const ERROR_PATH_404 = '/404'
const ERROR_PATH_404_EVENT = '/not-found-event'
const ERROR_PATH_404_PROMO = '/not-found-promo'

const ERROR_PATHS = {
  ERROR_PATH_404,
  ERROR_PATH_404_EVENT,
  ERROR_PATH_404_PROMO,
}

const DINING_DETAIL_PAGE = 'dining_detail'
const DINING_MENU_PAGE = 'dining_menu'
const EVENT_DETAIL_PAGE = 'event_detail_page'
const EVENT_VENUE_DETAIL_PAGE = 'event_venue_detail_page'
const EVENT_CALENDAR_PAGE = 'event_calendar_page'
const FORM_PAGE = 'form'
const LANDING_PAGE = 'landing_page'
const LISTING_PAGE = 'listing_page'
const GENERAL_TEMPLATE = 'general_template'
const PROMO_DETAIL_PAGE = 'promo_detail_page'
const POST_DETAIL_PAGE = 'post_detail_page'
const HOME_PAGE = 'home_page'
const POST_LISTING_PAGE = 'post_listing_page'
const POKER_ROOM_LANDING_PAGE = 'poker_room_landing_page'

//Icons

const ICON_AIRPLANE = 'Airplane'
const ICON_BAG = 'Bag'
const ICON_BALLOT = 'Ballot'
const ICON_BILL = 'Money'
const ICON_BOAT = 'Boat'
const ICON_CAKE = 'Cake'
const ICON_CAMERA = 'Camera'
const ICON_CAR = 'Car'
const ICON_CARDS = 'Playing Cards'
const ICON_CART = 'Cart'
const ICON_CHEQUE = 'Cheque'
const ICON_COCKTAIL = 'Cocktail'
const ICON_COINS = 'Gold Coins'
const ICON_CONCIERGE = 'Concierge'
const ICON_DEVICE = 'Device'
const ICON_DICE = 'Dice'
const ICON_DINING = 'Dining'
const ICON_DOOR = 'Door'
const ICON_DOWNLOAD = 'Download'
const ICON_FOOD = 'Food'
const ICON_GIFT = 'Wrapped Gift'
const ICON_ID = 'Id'
const ICON_KEYS = 'Keys'
const ICON_LETTER = 'Letter'
const ICON_LIMO = 'Limo'
const ICON_MAILBOX = 'Mailbox'
const ICON_MIC = 'Microphone'
const ICON_BINGO = 'Bingo'
const ICON_PC = 'Pc'
const ICON_POKER = 'Poker'
const ICON_POPSICLE = 'Popsicle'
const ICON_QUESTIONS = 'Trivia'
const ICON_RESET = 'Reset'
const ICON_ROPE = 'VIP Rope'
const ICON_RUSHREWARDS = 'Rush Rewards'
const ICON_SEAT = 'Seat'
const ICON_SLOTS = 'Slot Machine'
const ICON_PROMO = 'Three Stars'
const ICON_STARS = 'Three Stars'
const ICON_STRIKES = 'Strikes'
const ICON_TICKET = 'Ticket'
const ICON_TOQUE = 'Toque'
const ICON_TROPHY = 'Trophy'

//Promo Icon
const PROMO_CATEGORY_MONTHLY_PROMO = 'Monthly Promotion'
const PROMO_CATEGORY_SLOT_PROMO = 'Slot Promotion'
const PROMO_CATEGORY_SLOT_TOURN = 'Slot Tournament'
const PROMO_CATEGORY_CELEBRITY = 'Celebrity Meet & Greet'
const PROMO_CATEGORY_FOOD = 'Food Promotion'
const PROMO_CATEGORY_TABLE_GAMES_PROMO = 'Table Games Promotion'
const PROMO_CATEGORY_TABLE_GAMES_TOURN = 'Table Games Tournament'
const PROMO_CATEGORY_VIP_PROMO = 'VIP Promotion'
const PROMO_CATEGORY_CRUISE = 'Cruise Giveaway'
const PROMO_CATEGORY_POKER_PROMO = 'Poker Promotion'
const PROMO_CATEGORY_POKER_TOURN = 'Poker Tournament'
const PROMO_CATEGORY_BLACKJACK_PROMO = 'Blackjack Promotion'
const PROMO_CATEGORY_BLACKJACK_TOURN = 'Blackjack Tournament'
const PROMO_CATEGORY_GIFT = 'Gift Giveaway'
const PROMO_CATEGORY_JACKPOT_PARTY = 'Jackpot Party'
const PROMO_CATEGORY_JACKPOT_DRAWING = 'Jackpot Drawing'
const PROMO_CATEGORY_UNITED_POINTS = 'United Points Promotion'
const PROMO_CATEGORY_TRIP = 'Trip Giveaway'
const PROMO_CATEGORY_NEW_MEMBER = 'New Member Promotion'
const PROMO_CATEGORY_BINGO_PROMO = 'Bingo Promotion'
const PROMO_CATEGORY_CAR_GIVEAWAY = 'Car Giveaway'
const PROMO_CATEGORY_BIRTHDAY = 'Birthday Promotion'
const PROMO_CATEGORY_SPORTSBOOK = 'Sportsbook'
const PROMO_CATEGORY_ASIAN_GAMING = 'Asian Gaming'
const PROMO_CATEGORY_SOCIAL_PROMOTIONS = 'Social Promotions'

//Evebt Icon

const EVENT_CATEGORY_ENTERTAINER = 'Entertainer'
const EVENT_CATEGORY_FOOD = 'Food'
const EVENT_CATEGORY_TICKETED = 'Ticketed Event'
const EVENT_CATEGORY_TRIVIA = 'Trivia'

const BADGE_TYPES = [
  ICON_AIRPLANE,
  ICON_BAG,
  ICON_BALLOT,
  ICON_BILL,
  ICON_BOAT,
  ICON_CAKE,
  ICON_CAMERA,
  ICON_CAR,
  ICON_CARDS,
  ICON_CART,
  ICON_CHEQUE,
  ICON_COCKTAIL,
  ICON_COINS,
  ICON_CONCIERGE,
  ICON_DEVICE,
  ICON_DICE,
  ICON_DINING,
  ICON_DOOR,
  ICON_DOWNLOAD,
  ICON_FOOD,
  ICON_GIFT,
  ICON_ID,
  ICON_KEYS,
  ICON_LETTER,
  ICON_LIMO,
  ICON_MAILBOX,
  ICON_MIC,
  ICON_BINGO,
  ICON_PC,
  ICON_POKER,
  ICON_POPSICLE,
  ICON_QUESTIONS,
  ICON_RESET,
  ICON_ROPE,
  ICON_RUSHREWARDS,
  ICON_SEAT,
  ICON_SLOTS,
  ICON_PROMO,
  ICON_STRIKES,
  ICON_TICKET,
  ICON_TOQUE,
  ICON_TROPHY,
]

const TICKER_TYPES = [
  ICON_BILL,
  ICON_CARDS,
  ICON_COINS,
  ICON_DEVICE,
  ICON_DICE,
  ICON_DINING,
  ICON_DOWNLOAD,
  ICON_GIFT,
  ICON_MIC,
  ICON_ROPE,
  ICON_RUSHREWARDS,
  ICON_SLOTS,
  ICON_PROMO,
  ICON_TICKET,
  ICON_TOQUE,
  ICON_TROPHY,
]

const EVENT_ICON_TYPES = [
  EVENT_CATEGORY_ENTERTAINER,
  EVENT_CATEGORY_FOOD,
  EVENT_CATEGORY_TICKETED,
  EVENT_CATEGORY_TRIVIA,
]

const PROMO_ICON_TYPES = [
  PROMO_CATEGORY_MONTHLY_PROMO,
  PROMO_CATEGORY_SLOT_PROMO,
  PROMO_CATEGORY_SLOT_TOURN,
  PROMO_CATEGORY_CELEBRITY,
  PROMO_CATEGORY_FOOD,
  PROMO_CATEGORY_TABLE_GAMES_PROMO,
  PROMO_CATEGORY_TABLE_GAMES_TOURN,
  PROMO_CATEGORY_VIP_PROMO,
  PROMO_CATEGORY_CRUISE,
  PROMO_CATEGORY_POKER_PROMO,
  PROMO_CATEGORY_POKER_TOURN,
  PROMO_CATEGORY_BLACKJACK_PROMO,
  PROMO_CATEGORY_BLACKJACK_TOURN,
  PROMO_CATEGORY_GIFT,
  PROMO_CATEGORY_JACKPOT_PARTY,
  PROMO_CATEGORY_JACKPOT_DRAWING,
  PROMO_CATEGORY_UNITED_POINTS,
  PROMO_CATEGORY_TRIP,
  PROMO_CATEGORY_NEW_MEMBER,
  PROMO_CATEGORY_BINGO_PROMO,
  PROMO_CATEGORY_CAR_GIVEAWAY,
  PROMO_CATEGORY_BIRTHDAY,
  PROMO_CATEGORY_SPORTSBOOK,
  PROMO_CATEGORY_ASIAN_GAMING,
  PROMO_CATEGORY_SOCIAL_PROMOTIONS,
]

const ALL_ICON_TYPES = {
  ICON_AIRPLANE,
  ICON_BAG,
  ICON_BALLOT,
  ICON_BILL,
  ICON_BOAT,
  ICON_CAKE,
  ICON_CAMERA,
  ICON_CAR,
  ICON_CARDS,
  ICON_CART,
  ICON_CHEQUE,
  ICON_COCKTAIL,
  ICON_COINS,
  ICON_CONCIERGE,
  ICON_DEVICE,
  ICON_DICE,
  ICON_DINING,
  ICON_DOOR,
  ICON_DOWNLOAD,
  ICON_FOOD,
  ICON_GIFT,
  ICON_ID,
  ICON_KEYS,
  ICON_LETTER,
  ICON_LIMO,
  ICON_MAILBOX,
  ICON_MIC,
  ICON_BINGO,
  ICON_PC,
  ICON_POKER,
  ICON_POPSICLE,
  ICON_QUESTIONS,
  ICON_RESET,
  ICON_ROPE,
  ICON_RUSHREWARDS,
  ICON_SEAT,
  ICON_STARS,
  ICON_SLOTS,
  ICON_PROMO,
  ICON_STRIKES,
  ICON_TICKET,
  ICON_TOQUE,
  ICON_TROPHY,
  PROMO_CATEGORY_MONTHLY_PROMO,
  PROMO_CATEGORY_SLOT_PROMO,
  PROMO_CATEGORY_SLOT_TOURN,
  PROMO_CATEGORY_CELEBRITY,
  PROMO_CATEGORY_FOOD,
  PROMO_CATEGORY_TABLE_GAMES_PROMO,
  PROMO_CATEGORY_TABLE_GAMES_TOURN,
  PROMO_CATEGORY_VIP_PROMO,
  PROMO_CATEGORY_CRUISE,
  PROMO_CATEGORY_POKER_PROMO,
  PROMO_CATEGORY_POKER_TOURN,
  PROMO_CATEGORY_BLACKJACK_PROMO,
  PROMO_CATEGORY_BLACKJACK_TOURN,
  PROMO_CATEGORY_GIFT,
  PROMO_CATEGORY_JACKPOT_PARTY,
  PROMO_CATEGORY_JACKPOT_DRAWING,
  PROMO_CATEGORY_UNITED_POINTS,
  PROMO_CATEGORY_TRIP,
  PROMO_CATEGORY_NEW_MEMBER,
  PROMO_CATEGORY_BINGO_PROMO,
  PROMO_CATEGORY_CAR_GIVEAWAY,
  PROMO_CATEGORY_BIRTHDAY,
  PROMO_CATEGORY_SPORTSBOOK,
  PROMO_CATEGORY_ASIAN_GAMING,
  PROMO_CATEGORY_SOCIAL_PROMOTIONS,
  EVENT_CATEGORY_ENTERTAINER,
  EVENT_CATEGORY_FOOD,
  EVENT_CATEGORY_TICKETED,
  EVENT_CATEGORY_TRIVIA,
}

//Update this so schema is generated correctly
const PAGE_TYPES = [
  DINING_DETAIL_PAGE,
  DINING_MENU_PAGE,
  EVENT_DETAIL_PAGE,
  EVENT_VENUE_DETAIL_PAGE,
  FORM_PAGE,
  HOME_PAGE,
  LANDING_PAGE,
  LISTING_PAGE,
  PROMO_DETAIL_PAGE,
  POST_DETAIL_PAGE,
  GENERAL_TEMPLATE,
  POST_LISTING_PAGE,
  POKER_ROOM_LANDING_PAGE,
]

const TAGGED_SLICES = ['tag_grid_module', 'calendar_module']

const FEATURED_CARD = 'Featured Card'
const LANDING_CARD = 'Landing Card'
const IMAGE_HEADLINE_COPY_CARD = 'Image Headline Copy Card'
const HEADLINE_COPY_CARD = 'Headline Copy Card'
const IMAGE_HEADLINE_CARD = 'Image Headline Card'
const WIDE_CARD = 'Wide Card'
const STRIP_CARD = 'Strip Card'
const EVENT_VENUE_CARD = 'Event Venue Card'
const GRADIENT_CARD = 'Gradient Card /w Optional Button'
const WHITE_CARD = 'White Card /w Optional Text Link'
const CLEAR_CARD = 'Clear Card /w Optional Text Link'
const POST_CARD = 'Post Card'

const GRID_GALLERY = 'Gallery'
const GRID_STACK = 'Stacked'
const GRID_STACK_SHOW_MORE = 'Stacked With Show More'
const GRID_STACK_CONDENSED = 'Stacked Condensed'

const GRID_MODULE_MOBILE_BEHAVIORS = [
  GRID_GALLERY,
  GRID_STACK,
  GRID_STACK_SHOW_MORE,
  GRID_STACK_CONDENSED,
]

const GRID_MODULE_STYLES = [
  FEATURED_CARD,
  LANDING_CARD,
  //The next 3 should all use the same component, these options should be used to force not rendering content even if it exists.
  IMAGE_HEADLINE_COPY_CARD, //This one is the default configuration, if this one is selected but content is not there it should just look the other 2 appropriately
  HEADLINE_COPY_CARD,
  IMAGE_HEADLINE_CARD,
  //This one is the above except that it overrides the columns to 2
  WIDE_CARD,
  STRIP_CARD,
  EVENT_VENUE_CARD,
]

const TAGGED_GRID_MODULE_STYLES = [FEATURED_CARD, EVENT_VENUE_CARD]

const N_UP_FEATURE_MODULE_STYLES = [GRADIENT_CARD, WHITE_CARD, CLEAR_CARD]

module.exports = {
  AWS_DEFAULT_REGION,
  AWS_LAMBDA_BUILD_ARN,
  AWS_S3_BUCKET_NAME_EDGE_REDIRECT,
  ALL_ICON_TYPES,
  CLOUDSEARCH_DOCUMENT_ENDPOINT,
  CURATOR_API_KEY,
  BADGE_TYPES,
  TICKER_TYPES,
  EVENT_ICON_TYPES,
  PROMO_ICON_TYPES,
  CURATOR_INSTAGRAM_FEED_ID,
  CURATOR_TWITTER_FEED_ID,
  CARD_STYLES: {
    FEATURED_CARD,
    LANDING_CARD,
    IMAGE_HEADLINE_COPY_CARD,
    HEADLINE_COPY_CARD,
    IMAGE_HEADLINE_CARD,
    WIDE_CARD,
    STRIP_CARD,
    EVENT_VENUE_CARD,
    GRADIENT_CARD,
    WHITE_CARD,
    CLEAR_CARD,
    POST_CARD,
  },
  DINING_DETAIL_PAGE,
  DINING_MENU_PAGE,
  EVENT_DETAIL_PAGE,
  EVENT_CALENDAR_PAGE,
  EVENT_VENUE_DETAIL_PAGE,
  FORM_PAGE,
  GRID_MODULE_MOBILE_BEHAVIORS,
  GATSBY_FORM_URL,
  GATSBY_PATH_PREFIX,
  GATSBY_GOOGLE_MAPS_API_KEY,
  GATSBY_SITE_URL,
  GATSBY_CLOUDSEARCH_SEARCH_ENDPOINT,
  GENERAL_TEMPLATE,
  GRID_MODULE_STYLES,
  HOME_PAGE,
  LANDING_PAGE,
  LISTING_PAGE,
  N_UP_FEATURE_MODULE_STYLES,
  NODE_ENV,
  PAGE_TYPES,
  POKER_ROOM_LANDING_PAGE,
  POST_DETAIL_PAGE,
  POST_LISTING_PAGE,
  PRISMIC_API_KEY,
  PRISMIC_REPO_NAME,
  PROMO_DETAIL_PAGE,
  RULE_PREFIX_SCHEDULE,
  TAGGED_GRID_MODULE_STYLES,
  TAGGED_SLICES,
  GRID_GALLERY,
  GRID_STACK,
  GRID_STACK_SHOW_MORE,
  GRID_STACK_CONDENSED,
  ERROR_PATHS,
  INDEX_SEARCH,
}
