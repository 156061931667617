import ReactHtmlParser from 'react-html-parser'

export const createLinks = text => {
  const regEx = /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi
  return (text || '').replace(regEx, (match, space, url) => {
    let hyperlink = url
    if (!hyperlink.match('^https?://')) hyperlink = `http://${hyperlink}`
    return `${space}<a href="${hyperlink}" target="_blank" rel="noopener noreferrer"  tabIndex="-1">${url}</a>`
  })
}

export const parseTwitterHashtags = (text = '') =>
  text.replace(
    /#(\w+)/g,
    '<span><a href="https://twitter.com/hashtag/$1?src=hash" target="_blank" rel="noopener noreferrer"  tabIndex="-1">#$1</a></span>',
  )

export const parseScreenNames = (text = '', url) =>
  text.replace(
    /@(\w+)/g,
    `<span><a href="${url}$1" target="_blank" rel="noopener noreferrer" tabIndex="-1">@$1</a></span>`,
  )

export const parseQuery = (text, query) => {
  let str = text.replace(query, `<span>${query}</span>`)
  str = str.replace(query.toLowerCase(), `<span>${query.toLowerCase()}</span>`)
  str = str.replace(query.toUpperCase(), `<span>${query.toUpperCase()}</span>`)
  return ReactHtmlParser(str)
}

export const formatTweet = text => {
  let formatedText = parseTwitterHashtags(text)
  formatedText = parseScreenNames(formatedText, 'https://twitter.com/')
  formatedText = createLinks(formatedText)
  return ReactHtmlParser(formatedText)
}

export const formatGoogleMapsLink = address => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`
}
