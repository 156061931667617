import { WEIGHT } from '../style/type'

import { em, stripUnit, rem } from 'polished'
import { css, keyframes } from 'styled-components'
import { mapValues, get } from 'lodash'
import Bowser from 'bowser'

export let device = { mobile: false }
export let isMobile = false
if (typeof window !== 'undefined') {
  device = Bowser.getParser(window.navigator.userAgent)
  const type = get(device, 'parsedResult.platform.type')
  isMobile = device.mobile = type === 'mobile' || type === 'tablet'
}

// px
export const breakpointsPx = {
  xxs: 0,
  xs: 321,
  sm: 356,
  md: 577,
  lg: 769,
  xl: 993,
  xxl: 1200,
}

// em
export const breakpoints = mapValues(breakpointsPx, value =>
  stripUnit(em(value)),
)

export const color = {
  accentBlue: '#008FBE',
  accentGrape: '#867BD2',
  accentLightBlue: '#95D4E9',
  accentPlum: '#AE6573',
  accentOrange: '#EE907B',
  accentTeal: '#3BBFAD',

  blue: '#1D355B',
  yellow: '#FDB61B',
  red: '#FF455B',

  secondaryBlue: '#316094',
  secondaryLightBlue: '#CAD1EA',
  secondaryYellow: '#F8DF8D',

  softBlue: '#F3F5F7',
  neutralBlue: '#455073',

  gradientStart: '#3E6594',
  gradientEnd: '#1B365D',

  navTextGrey: '#677891',

  rule: '#E8ECF3',
  ruleAlt: '#dde2f1',
  hotelBookingGrey: '#EAEEF4',
}

// px
export const fontSizes = {
  xxs: 11, // h6
  xs: 13, // button, cta
  sm: 14, // h5, microcopy
  md: 15, // p
  lg: 18, // article
  xl: 20, // h4
  xxl: 24, // h3, blockquote
  xxxl: 32, // h2
  xxxxl: 46, // h1
}

export const fontSizesSmall = {
  xxs: 10, // h6
  xs: 12, // button, cta
  sm: 13, // h5
  md: 14, // p
  lg: 15, // article
  xl: 18, // h4, blockquote
  xxl: 22, // h3
  xxxl: 26, // h2
  xxxxl: 30, // h1
}

const spacingBase = 10
export const space = (multipier = 2) => rem(spacingBase * multipier)

const verticalPaddingTopMenu = css`
  padding-top: ${space(4)};

  @media (min-width: ${breakpointsPx.lg}px) {
    padding-top: ${space(5)};
  }
`

const verticalPaddingTop = css`
  padding-top: ${space(4)};

  @media (min-width: ${breakpointsPx.lg}px) {
    padding-top: ${space(8)};
  }
`

const verticalPaddingBottom = css`
  padding-bottom: ${space(4)};

  @media (min-width: ${breakpointsPx.lg}px) {
    padding-bottom: ${space(8)};
  }
`

const verticalPadding = css`
  ${verticalPaddingTop};
  ${verticalPaddingBottom};
`

const bgGradient = css`
  background-color: ${color.blue};
  background-image: linear-gradient(145deg, #3e6594 0%, #1d355b 50%);
`

const blueLink = css`
  position: relative;
  color: ${color.secondaryBlue};
  font-weight: ${WEIGHT.MEDIUM};
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: ${color.secondaryLightBlue};
    transition: all 0.3s;
  }

  &:hover {
    &:after {
      background-color: ${color.accentBlue};
    }
  }
`

export const layout = {
  slicePadding: {
    xs: 50,
    md: 60,
    xl: 70,
  },
  navHeight: {
    xs: 100,
    sm: 100,
    md: 100,
    lg: 100,
    xl: 180,
  },
  border: rem(1),
  borderRadius: rem(5),
  bgGradient,
  maxWidth: rem(1410),
  maxWidthPad: rem(1490),
  maxWidthSm: rem(1170),
  maxWidthArticle: rem(930),
  maxWidthPost: rem(690), // posts, form
  sectionVerticalMargin: rem(80),
  spacingBase,
  verticalPadding,
  verticalPaddingBottom,
  verticalPaddingTop,
  verticalPaddingTopMenu,
}

const opacityHover = css`
  transition: opacity 0.4s ease-in;
  cursor: pointer;

  &:hover {
    transition: opacity 0.3s ease-out;
    opacity: 0.7;
  }
`

const secondaryBlueHover = ({ theme }) => css`
  transition: color 0.4s ease-in;
  cursor: pointer;

  &:hover {
    transition: color 0.3s ease-out;
    color: ${theme.color.blue};
  }
`

const afterFocus = ({ showFocus, theme }) => css`
  ${showFocus &&
    css`
      &:focus {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid ${theme.color.accentLightBlue};
          box-shadow: inset 0 0 5px ${theme.color.accentLightBlue};
          background-color: transparent;
          pointer-events: none;
        }
      }
    `};
`

const fadeInFrames = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

const fadeIn = (delay = 0, time = 0.7) => css`
  animation: ${fadeInFrames} ${time}s ease-out ${delay}s both;
`

export const dropShadow = css`
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.2);
`

const imgHover = css`
  .aspect-img img {
    //transform: scale(1.03);
    // opacity: 0.75;
  }
`

/*
  This creates the media templates, which allows for simple
  breakpoint usage inside styled-components, e.g.:

  ${theme.media.sm`
    font-size: 1.6rem;
  `};

  ${theme.media.lg`
    display: flex;
  `};
 */

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}em) {
      ${css(...args)};
    }
  `
  return acc
}, {})

const theme = {
  breakpoints,
  breakpointsPx,
  color,
  device,
  flexa: {
    columns: 12,
    gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
      xxl: 0,
    },
    breakpoints,
  },
  fontSizes,
  fontSizesSmall,
  layout,
  media, // media queries
  mixin: {
    afterFocus,
    blueLink,
    dropShadow,
    fadeIn,
    imgHover,
    opacityHover,
    secondaryBlueHover,
  },
  space,
}

export default theme
