import { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const isServer = typeof document === 'undefined'

class Portal extends Component {
  static propTypes = {
    children: PropTypes.node,
    targetId: PropTypes.string,
    id: PropTypes.string,
    zIndex: PropTypes.number,
  }

  static defaultProps = {
    targetId: 'portal',
    zIndex: 999999,
  }

  constructor(props) {
    super(props)

    if (isServer) return
    this.el = document.getElementById(props.id || props.targetId)

    if (!props.id) this.el.id = props.targetId
    this.el.style.zIndex = props.zIndex
  }

  render() {
    return this.el ? ReactDOM.createPortal(this.props.children, this.el) : null
  }
}

export default Portal
