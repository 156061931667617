export const SANS_SERIF = `"Gotham A", "Gotham B", Gotham, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial,
    sans-serif`

export const WEIGHT = {
  BLACK: 700,
  BOLD: 600,
  MEDIUM: 500,
  BOOK: 400,
  LIGHT: 300,
}
