import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import WaveLogo from '../../assets/logo-mark.inline.svg'

const SplashLoader = ({}) => {
  return (
    <WaveLogoContainer>
      <WaveLogo></WaveLogo>
    </WaveLogoContainer>
  )
}

SplashLoader.propTypes = {}

export default SplashLoader

const loadingAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0,0);
  }
  10% {
    opacity: 1;
  }
  
  40% {
    transform: scale(1.5,1.5);

  }
  
 70% {
    transform: scale(1, 1);
 }
 80% {
  transform: scale(1, 1);
  opacity: 1;
 }
 
 100% {
  transform: scale(2, 2);
  opacity: 0;
 }

`

const WaveLogoContainer = styled.div(
  ({ theme }) => css`
    animation-name: ${loadingAnimation};
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    position: relative;
  `,
)
