import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from 'react'
import PropTypes from 'prop-types'

import Menu from './Menu'
import Text from '../../common/Text'
import Link from '../../common/Link'
import LinkContainer, {
  LinkContainerItem,
  itemMotionProps,
  HoverBg,
} from './LinkContainer'
import { Close, LinkOut, Hamburger, Search } from '../../common/Icons'
import SearchForm, { Form } from './SearchForm'
import { motion } from 'framer-motion'
import Sticky from 'react-stickynode'
import WaveLogo from '../../../assets/logo-mark.inline.svg'
import NavItem from '../nav/NavItem'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get, keyBy, isNull } from 'lodash'
import { useAppContext } from '../../../context/AppContext'
import linkResolver from '../../../utils/linkResolver'
import hoverBg from '../../../assets/sub-nav-hover.png'

const NAV_HEIGHT_SM = 100
const minHeight = `calc(100vh - ${rem(NAV_HEIGHT_SM)})`

const contentMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: 'tween',
    duration: 0.2,
    ease: 'easeOut',
  },
}

const DropDownNavItem = ({ close, item, len, index }) => {
  const { focusStyleOn } = useAppContext()
  const label = get(item, 'label')
  const header = get(item, 'label_header')
  const link = {
    href: linkResolver(item, 'link'),
    target: get(item, 'link.target'),
  }
  const extLink = link.target === '_blank'

  const viewAllLabel = item.viewAll
    ? {
        size: 14,
        spacing: 1.4,
        medium: false,
        bold: true,
      }
    : {}

  return (
    <LinkContainerItem
      columns={len}
      extLink={extLink}
      hasHeader={header}
      index={index}
      isBlue
      showFocus={focusStyleOn}
      variants={itemMotionProps}
    >
      <HoverBg>
        <img alt="" src={hoverBg} />
      </HoverBg>
      <Link {...link} onClick={close}>
        {header && (
          <HeaderLabel center color="yellow" uppercase {...viewAllLabel}>
            {header}
          </HeaderLabel>
        )}
        <Text.h5
          center
          color="#fff"
          lg={item.viewAll ? 'xl' : 'sm'}
          size={item.viewAll ? 'xl' : 'sm'}
          medium={!item.viewAll}
          bold={item.viewAll}
          css={{ marginTop: rem(item.viewAll ? -4 : 0) }}
        >
          {label}
        </Text.h5>
        {extLink && <LinkOut fill="secondaryLightBlue" />}
      </Link>
    </LinkContainerItem>
  )
}

DropDownNavItem.propTypes = {
  close: PropTypes.func,
  index: PropTypes.number,
  item: PropTypes.object,
  len: PropTypes.number,
  viewAll: PropTypes.bool,
}

const Nav = ({ nav, ...props }) => {
  const {
    siteInit,
    setSiteInit,
    menuModalOpen,
    setMenuModalOpen,
    focusStyleOn,
    setShowAllSearchResults,
    isLarge,
    isXLarge,
    showAllSearchResults,
    showNavSearch,
    setShowNavSearch,
  } = useAppContext()
  const modal = useRef()
  const [isSticky, setSticky] = useState(false)
  const [activeNavId, setNavId] = useState(null)
  const [showMenu, setShowMenu] = useState(false)
  const [showModal, setModal] = useState(false)
  const image = {
    src: get(nav, 'logo_image.url'),
    alt: get(nav, 'logo_image.alt'),
  }
  const links = get(nav, 'body')
  const keys = keyBy(links, 'id')
  const activeLink = get(keys, `${activeNavId}.primary`)
  let navItemList = get(keys, `${activeNavId}.items`)
  const hasViewAll = get(activeLink, 'view_all_label')
  if (hasViewAll) {
    const viewAll = {
      label_header: get(activeLink, 'view_all_header'),
      label: hasViewAll,
      link: get(activeLink, 'view_all_link'),
      viewAll: true,
    }
    navItemList = [...get(keys, `${activeNavId}.items`), viewAll]
  }

  const handleActive = () => setNavId(activeNavId)
  const handleLeave = () => setNavId(null)

  const len = get(navItemList, 'length') || 0
  let navRow1 = navItemList
  let navRow2 = null

  // Handle odd rows with css grid
  if (len > 3 && len % 2) {
    const row2 = Math.round(len / 2)
    const row1 = len - row2
    navRow1 = navItemList.slice(0, row1)
    navRow2 = navItemList.slice(row2 - 1)
  }

  const row1Len = get(navRow1, 'length') || len
  const columns = row1Len === 6 ? 3 : Math.min(row1Len, 4)

  const closeModal = useCallback(() => {
    const close = get(modal, 'current.close')
    if (close) close()
    if (menuModalOpen) setMenuModalOpen(false)
  }, [modal, menuModalOpen, setMenuModalOpen])

  const toggleSearch = () => {
    if (showMenu) setShowMenu(false)
    setShowNavSearch(!showNavSearch)
    setMenuModalOpen(true)
  }

  const toggleMenu = () => {
    if (showNavSearch) setShowNavSearch(false)
    setShowMenu(!showMenu)
    setMenuModalOpen(true)
  }

  useEffect(() => {
    const modalHasContent = !!showMenu || !!showNavSearch
    if (modalHasContent) {
      if (!showModal && !isXLarge) setModal(true)
    } else {
      if (showModal && !isXLarge) closeModal()
    }
  }, [closeModal, showMenu, showNavSearch, showModal, isXLarge])

  useEffect(() => {
    if (showMenu && isXLarge) {
      setShowMenu(false)
      closeModal()
    }
  }, [showMenu, isXLarge, closeModal])

  useEffect(() => {
    if (menuModalOpen && isXLarge) {
      closeModal()
    }
  }, [closeModal, isXLarge, menuModalOpen])

  useEffect(() => {
    if (activeNavId && !isXLarge) {
      setNavId(null)
      closeModal()
    }
  }, [activeNavId, closeModal, isXLarge])

  useEffect(() => {
    if (!showNavSearch) setShowAllSearchResults(false)
  }, [setShowAllSearchResults, showNavSearch])

  useEffect(() => {
    const timer = setTimeout(() => {
      setSiteInit(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [setSiteInit])

  const Wrapper = isLarge ? Sticky : Fragment
  const wrapperProps = isLarge
    ? {
        onStateChange: e => {
          setSticky(get(e, 'status') === 2)
        },
        top: 0,
      }
    : {}

  const showSticky = isLarge && isSticky

  return (
    <Wrapper {...wrapperProps}>
      <Container sticky={showSticky} init={siteInit}>
        <Content>
          <Row>
            <Link aria-label="Logo" href="/">
              {!showSticky && image && <Logo {...image} />}
              {showSticky && (
                <Wave>
                  <WaveLogo />
                </Wave>
              )}
            </Link>

            {(!showNavSearch || showAllSearchResults) && isXLarge && (
              <LinkGroup init={siteInit}>
                {links &&
                  links.map(item => {
                    const handleEnter = () => setNavId(item.id)
                    return (
                      <NavItem
                        key={item.id}
                        activeNavId={activeNavId}
                        handleMouseEnter={handleEnter}
                        handleMouseLeave={handleLeave}
                        {...item}
                      />
                    )
                  })}
              </LinkGroup>
            )}

            {isXLarge && showNavSearch && !showAllSearchResults && (
              <>
                <AbsCenterSearch>
                  <SearchForm />
                </AbsCenterSearch>
                <Stretch />
              </>
            )}

            <SearchButton
              init={siteInit}
              isLarge={isLarge}
              showFocus={focusStyleOn}
              tabIndex={0}
              onClick={toggleSearch}
              active={showNavSearch}
              aria-label={`${showNavSearch ? 'Close' : 'Open'} Search`}
            >
              {showNavSearch ? (
                <Close width={18} height={18} />
              ) : (
                <Search width={18} height={18} />
              )}
            </SearchButton>

            {!isXLarge && (
              <HamburgerButton
                init={siteInit}
                isLarge={isLarge}
                showFocus={focusStyleOn}
                tabIndex={0}
                onClick={toggleMenu}
                active={showMenu}
                aria-label={`${showMenu ? 'Close' : 'Open'} Menu`}
              >
                {showMenu ? (
                  <Close width={18} height={18} />
                ) : (
                  <Hamburger className="menu" width={25} height={25} />
                )}
              </HamburgerButton>
            )}
          </Row>
        </Content>
      </Container>

      {isXLarge && (
        <HoverArea
          sticky={isSticky}
          onMouseEnter={() => setNavId(activeNavId)}
        />
      )}

      <Positioner onMouseEnter={handleActive} onMouseLeave={handleLeave}>
        <LinkContainer
          show={!isNull(activeNavId)}
          columns={len}
          uId={activeNavId}
          sticky={showSticky}
        >
          {navRow1 && (
            <Grid columns={columns} key={`${activeNavId}-grid-1`}>
              {navRow1.map((item, i) => (
                <DropDownNavItem
                  key={activeNavId + i}
                  index={i}
                  item={item}
                  len={len}
                  close={handleLeave}
                />
              ))}
            </Grid>
          )}
          {navRow2 && (
            <Grid
              columns={Math.min(navRow2.length, 4)}
              padTop
              key={`${activeNavId}-grid-2`}
            >
              {navRow2.map((item, i) => (
                <DropDownNavItem
                  key={activeNavId + (i + navRow1.length)}
                  item={item}
                  len={len}
                  close={handleLeave}
                />
              ))}
            </Grid>
          )}
        </LinkContainer>
      </Positioner>

      {!isXLarge && (
        <div>
          <MenuContainer
            css={{
              top: rem(NAV_HEIGHT_SM),
              zIndex: 99999,
              height: showMenu || showAllSearchResults ? '100%' : 'auto',
              minHeight: showMenu ? minHeight : 'none',
            }}
          >
            <>
              <MenuContent
                key="menu"
                {...contentMotion}
                css={{ display: showMenu ? 'flex' : 'none' }}
              >
                <Menu closeModal={toggleMenu} links={links} {...props} />
              </MenuContent>

              {!showAllSearchResults && showNavSearch && (
                <SearchContent key="search" {...contentMotion}>
                  <SearchForm />
                  <div id="hint-results-portal-mobile" />
                </SearchContent>
              )}
            </>
          </MenuContainer>
        </div>
      )}

      <div id="hint-results-portal" />
    </Wrapper>
  )
}

Nav.propTypes = {
  nav: PropTypes.object,
}

export default Nav

const HoverArea = styled.div`
  ${({ sticky, theme }) => css`
    display: none;

    position: absolute;
    bottom: ${sticky ? 0 : 22}px;
    left: 52%;
    width: 100%;
    height: ${sticky ? 20 : 30}px;
    width: calc(70vw);
    max-width: ${rem(850)};

    transform: translateX(-50%);

    ${theme.media.xl`
      display: block;
    `};
  `};
`

const Stretch = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    order: 2;
    max-width: ${rem(690)};
  `};
`

const AbsCenterSearch = styled.div`
  ${({ theme }) => css`
    display: none;

    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    ${theme.media.xl`
      display: flex;
    `};
  `};
`

const Positioner = styled.div`
  ${({ theme }) => css`
    position: relative;
    top: ${rem(-20)};
  `};
`

const Grid = styled.ul`
  ${({ columns, padTop, theme }) => css`
    grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
    display: grid;
    grid-gap: ${theme.space(3)} ${theme.space(3)};

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${padTop &&
      css`
        margin-top: ${theme.space(3)};
      `};
  `};
`

const Container = styled.nav`
  ${({ init, sticky, theme }) => css`
    ${!init && theme.mixin.fadeIn(0.1, 1.1)};

    display: flex;
    justify-content: center;

    width: 100%;
    height: ${rem(NAV_HEIGHT_SM)};

    background-color: ${theme.color.softBlue};
    padding: 0 ${theme.space(3)};

    ${sticky &&
      css`
        box-shadow: 0 0px 50px 0 rgba(0, 0, 0, 0.2);
      `};

    ${theme.media.md`
      padding-left: ${rem(45)};
      padding-right: ${rem(45)};
    `};

    ${theme.media.lg`
      height: ${rem(sticky ? 70 : NAV_HEIGHT_SM)};
      transition: height 0.3s ease-out;
    `};

    ${theme.media.xl`
      height: ${rem(sticky ? 70 : 130)};
      transition: height 0.3s ease-out;
    `};
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const MenuContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 0;
    width: 100%;
    min-width: 100vw;
    background-color: ${theme.color.softBlue};
  `};
`

const MenuContent = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: center;

    padding: ${theme.space(1)};

    background-color: ${theme.color.softBlue};

    > * {
      position: relative;
    }
  `};
`

const SearchContent = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: ${theme.space(1)};

    background-color: ${theme.color.softBlue};

    /* > * {
      position: relative;
    } */
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    > ${Form} {
      order: 2;
    }

    > a {
      order: 2;

      ${theme.media.xl`
        order: 1;
        margin-right: 2.5vw;
      `};

      ${theme.media.xxl`
        margin-right: ${theme.space(3.5)};
      `};
    }
  `};
`

const Logo = styled.img`
  ${({ theme }) => css`
    width: 100%;
    min-width: ${rem(90)};
    max-width: ${rem(104)};
    height: auto;
    object-fit: scale-down;

    ${theme.media.xl`
        min-width: ${rem(120)};
        max-width: ${rem(120)};
      `};
  `};
`

const Wave = styled.div`
  ${({ theme }) => css`
    svg {
      width: 100%;
      min-width: ${rem(90)};
      max-width: ${rem(104)};
      height: auto;
      object-fit: scale-down;

      ${theme.media.xl`
        max-width: ${rem(120)};
      `};
    }
  `};
`

const LinkGroup = styled.div`
  ${({ theme }) => css`
    display: none;

    justify-content: center;
    align-items: center;
    height: 100%;
    order: 2;

    ${theme.media.xl`
      display: flex;
    `};
  `}
`

const CircleButton = styled.button`
  ${({ active, showFocus, theme, isLarge }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    padding: 0;

    width: ${rem(55)};
    height: ${rem(55)};

    background-color: ${active ? theme.color.blue : '#fff'};
    border-radius: 100%;

    cursor: pointer;

    transition: background-color 0.3s ease-out;

    svg {
      path {
        transition: fill 0.3s ease-out;
        fill: ${active ? '#fff' : theme.color.blue};
      }
    }

    ${showFocus &&
      css`
        &:focus {
          border: 1px solid ${theme.color.accentLightBlue};
          box-shadow: 0 0 5px ${theme.color.accentLightBlue};
        }
      `};

    &:hover {
      svg path {
        transition: fill 0.3s ease-in;
      }

      ${isLarge &&
        css`
          background-color: #fff;

          svg path {
            fill: ${theme.color.blue};
          }
        `};

      transition: background-color 0.3s ease-in;
    }

    ${theme.media.xl`
      padding: ${theme.space(1.3)};
      width: ${rem(41)};
      height: ${rem(41)};
    `};
  `};
`

const SearchButton = styled(CircleButton)`
  ${({ active, isLarge, theme }) => css`
    order: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      ${!active &&
        isLarge &&
        css`
          background-color: ${theme.color.blue};

          svg path {
            fill: #fff;
          }
        `};
    }

    ${theme.media.xl`
      order: 3;
      margin-left: 2.5vw;

      svg {
        transform: translate(0.5px, -1px);
      }
    `};

    ${theme.media.xxl`
      margin-left: ${theme.space(3.5)};
    `};
  `};
`

const HamburgerButton = styled(CircleButton)`
  ${({ isLarge, theme }) => css`
    order: 3;

    &:hover {
      ${isLarge &&
        css`
          background-color: ${theme.color.blue};

          svg {
            path {
              fill: #fff;
            }
          }
        `};

    ${theme.media.xl`
      display: none;
    `};
  `};
`

const HeaderLabel = styled(Text.h6)`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(1)};
  `};
`
