import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Portal from './Portal'

import keyCodes from '../../constants/keyCodes'
import styled, { css } from 'styled-components'
import { get, isNull } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'

const transitionDict = {
  slideInFromLeft: {
    initial: { opacity: 0.5 },
    animate: { opacity: 1 },
    exit: { opacity: 0.5 },
  },
  fadeIn: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
}

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node,
    fixedId: PropTypes.string,
    id: PropTypes.string,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    onTransitionInComplete: PropTypes.func,
    hideModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    targetId: PropTypes.string,
    transitionType: PropTypes.string,
    zIndex: PropTypes.number,
  }

  static defaultProps = {
    zIndex: 99999,
  }

  state = {
    queuedToHide: false,
  }

  componentDidUpdate(prevProps) {
    const { fixedId, show } = this.props
    if (!prevProps.show && show) {
      this._addListeners(show)

      const el = document.getElementById(fixedId)
      if (el) el.style.position = 'fixed'
    }
  }

  componentWillUnmount() {
    this._addListeners(false)
    this._reset()
  }

  get queuedToHide() {
    return this.state.queuedToHide
  }

  close = () => this._handleClose()

  _addListeners = (add = true) => {
    if (typeof window !== 'undefined') {
      if (add) window.addEventListener('keydown', this._onKeyDown, false)
      else window.removeEventListener('keydown', this._onKeyDown)
    }
  }

  _onKeyDown = e => {
    const { onKeyDown } = this.props
    const key = e.which || e.keyCode
    if (key === keyCodes.ESC) this._handleClose()
    if (onKeyDown) onKeyDown(key)
  }

  _handleClose = () => {
    const { hideModal, show } = this.props
    if (show) {
      this._reset(true)
      if (hideModal) hideModal()
    }
  }

  _reset = (queuedToHide = false) => {
    const { fixedId, onClose } = this.props
    this.setState({ queuedToHide })

    if (!queuedToHide) {
      const el = document.getElementById(fixedId)
      if (el) el.style.position = ''
    }

    if (!isNull(this._timeout)) {
      clearTimeout(this._timeout)
      this._timeout = null
      if (!queuedToHide && onClose) onClose()
    }
  }

  _onAnimationComplete = () => {
    const { show, onTransitionInComplete } = this.props
    if (show && onTransitionInComplete) onTransitionInComplete()
  }

  render() {
    const { children, show, id, targetId, transitionType, zIndex } = this.props
    const { queuedToHide } = this.state
    const showAll = show || queuedToHide
    const transition =
      get(transitionDict, transitionType) || transitionDict.slideInFromLeft

    return showAll ? (
      <Portal id={id} targetId={targetId} zIndex={zIndex}>
        <AnimatePresence onExitComplete={this._reset}>
          {show && (
            <Container
              key="modal"
              {...transition}
              onAnimationComplete={this._onAnimationComplete}
              transition={{
                type: 'tween',
                duration: show ? 0.3 : 0.4,
                ease: show ? 'easeOut' : 'easeIn',
              }}
            >
              {children}
            </Container>
          )}
        </AnimatePresence>
      </Portal>
    ) : null
  }
}

export default Modal

const Container = styled(motion.div)`
  ${() => css`
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    left: 0;
    width: 100vw;

    pointer-events: none;

    > * {
      pointer-events: all;
    }
  `};
`
