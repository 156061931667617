import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { color } from '../../style/theme'

const PlusMinus = ({ itemOpen, ...props }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="arcs"
      itemOpen={itemOpen}
      {...props}
    >
      {!itemOpen && <line x1="12" y1="5" x2="12" y2="19"></line>}
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </Svg>
  )
}

PlusMinus.defaultProps = {
  colorOn: color.secondaryLightBlue,
  colorOff: color.yellow,
}

PlusMinus.propTypes = {
  itemOpen: PropTypes.bool,
  colorOn: PropTypes.string,
  colorOff: PropTypes.string,
}

export default PlusMinus

const Svg = styled.svg`
  ${({ colorOn, colorOff, itemOpen, theme }) => css`
    flex-shrink: 0;
    margin-left: ${theme.space(2)};
    stroke: ${itemOpen ? colorOn : colorOff};
    z-index: 2;
  `};
`
