import React from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import { Row, Col } from 'react-flexa'
import Text from '../../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { rem } from 'polished'
import { fontSizesSmall } from '../../../style/theme'
import { useAppContext } from '../../../context/AppContext'
import { formatGoogleMapsLink } from '../../../utils/formatText'
import linkResolver from '../../../utils/linkResolver'
import LinkComponent from '../../common/Link'
import { ICON_ATLAS } from '../../common/IconAtlas'

const textStyle = {
  medium: true,
  color: 'secondaryLightBlue',
  size: 'xs',
  lg: 'xs',
}

const capSizes = {
  xs: 'hidden',
  sm: 'hidden',
  md: 'hidden',
  lg: 'hidden',
  xl: 1,
}

const Footer = ({
  address,
  address_label,
  app_title,
  banner_text,
  contact_title,
  copyright,
  logo,
  site_links_title,
  site_links,
  title,
  phone_link,
  chat_link,
  facebook_link,
  twitter_link,
  instagram_link,
  yelp_link,
  trip_advisor,
  ios_link,
  android_link,
}) => {
  const { isLarge, isXLarge, menuModalOpen } = useAppContext()
  const svg = get(logo, 'url')
  const alt = get(logo, 'alt') || ''
  const addressLabel = get(address_label, 'text')
  const addressText = get(address, 'html')
  const addressLinkText = formatGoogleMapsLink(get(address, 'text'))
  const bannerText = get(banner_text, 'html')
  const copyrightText = get(copyright, 'html')
  const siteLinksLabel = get(site_links_title, 'text')
  const socialTitle = get(title, 'text') || 'Lets Connect'
  const contactLabel = get(contact_title, 'text') || 'Get In Touch'
  const appLabel = get(app_title, 'text') || 'Download Our App'
  const socialOrder = isLarge ? {} : { order: 2 }
  const applOrder = isLarge ? {} : { order: 1 }

  // const ChatIcon = ICON_ATLAS.Chat
  const PhoneIcon = ICON_ATLAS.Phone
  const FacebookIcon = ICON_ATLAS.Facebook
  const TwitterIcon = ICON_ATLAS.X
  const InstagramIcon = ICON_ATLAS.Instagram
  const YelpIcon = ICON_ATLAS.Yelp
  const TripadvisorIcon = ICON_ATLAS.Tripadvisor
  const IOSIcon = ICON_ATLAS.IOS
  const AndroidIcon = ICON_ATLAS.Android

  const phoneLink = {
    href: linkResolver({ phone_link }, 'phone_link'),
    target: get(phone_link, 'target'),
  }

  const chatLink = {
    href: linkResolver({ chat_link }, 'chat_link'),
    target: get(chat_link, 'target'),
  }

  const facebookLink = {
    href: linkResolver({ facebook_link }, 'facebook_link'),
    target: get(facebook_link, 'target'),
  }

  const twitterLink = {
    href: linkResolver({ twitter_link }, 'twitter_link'),
    target: get(twitter_link, 'target'),
  }

  const instagramLink = {
    href: linkResolver({ instagram_link }, 'instagram_link'),
    target: get(instagram_link, 'target'),
  }

  const yelpLink = {
    href: linkResolver({ yelp_link }, 'yelp_link'),
    target: get(yelp_link, 'target'),
  }
  const tripadvisorLink = {
    href: linkResolver({ trip_advisor }, 'trip_advisor'),
    target: get(trip_advisor, 'target'),
  }
  const iosLink = {
    href: linkResolver({ ios_link }, 'ios_link'),
    target: get(ios_link, 'target'),
  }

  const androidLink = {
    href: linkResolver({ android_link }, 'android_link'),
    target: get(android_link, 'target'),
  }

  const addressBlock = (
    <div>
      {addressLabel && (
        <Text.h6 className="address-label" white>
          {addressLabel}
        </Text.h6>
      )}

      {addressText && (
        <a href={addressLinkText} target="_blank" rel="noopener noreferrer">
          <Text {...textStyle} lineHeightMultiplier={1.48} lineHeightLg={1.7}>
            {ReactHtmlParser(addressText)}
          </Text>
        </a>
      )}
    </div>
  )

  const contactBlock = (
    <div>
      <Text.h6 white>{contactLabel}</Text.h6>
      <ul>
        {phoneLink.href && (
          <li>
            <LinkComponent {...phoneLink}>
              <ContactIcon marginBottom={false}>
                <PhoneIcon />
                <Text.p {...textStyle}>
                  {phoneLink.href.replace(/tel:/, '')}
                </Text.p>
              </ContactIcon>
            </LinkComponent>
          </li>
        )}
      </ul>
    </div>
  )

  return (
    <Container css={{ display: menuModalOpen ? 'none' : 'flex' }}>
      <StyledRow>
        <StyledCol {...capSizes} />

        <StyledCol xxs={12} xs={12} sm={12} md={12} lg="hidden" xl="hidden">
          {!isLarge && addressBlock}
        </StyledCol>

        <StyledCol xxs={12} xs={12} sm={12} md={12} lg="hidden" xl="hidden">
          {!isLarge && contactBlock}
        </StyledCol>

        <StyledCol
          xxs="hidden"
          xs="hidden"
          sm="hidden"
          md="hidden"
          lg="hidden"
          xl={2}
        >
          {svg && <Logo src={svg} alt={alt} />}
          {copyrightText && (
            <Text size={10} color="secondaryLightBlue">
              {ReactHtmlParser(copyrightText)}
            </Text>
          )}
        </StyledCol>

        <StyledCol xxs={12} xs={12} sm={12} md={12} lg={5} xl={4}>
          <div>
            {siteLinksLabel && <Text.h6 white>{siteLinksLabel}</Text.h6>}
            <SiteLinks>
              {site_links &&
                site_links.map(item => {
                  const copy = get(item, 'label')
                  const link = {
                    href: linkResolver(item, 'link'),
                    target: get(item, 'link.target'),
                  }
                  return (
                    <li key={copy}>
                      <LinkComponent {...link}>
                        <Text.p {...textStyle} ellipsis>
                          {copy}
                        </Text.p>
                      </LinkComponent>
                    </li>
                  )
                })}
            </SiteLinks>
          </div>
        </StyledCol>

        <StyledCol
          xxs={12}
          xs={12}
          sm={12}
          md={12}
          lg={3.5}
          xl={2}
          {...socialOrder}
        >
          <FlexCol>
            {isLarge && addressBlock}

            <SocialContainer>
              <Text.h6 white>{socialTitle}</Text.h6>
              <IconGroup>
                {facebookLink.href && (
                  <li>
                    <LinkComponent {...facebookLink}>
                      <FacebookIcon />
                    </LinkComponent>
                  </li>
                )}
                {twitterLink.href && (
                  <li>
                    <LinkComponent {...twitterLink}>
                      <TwitterIcon />
                    </LinkComponent>
                  </li>
                )}
                {instagramLink.href && (
                  <li>
                    <LinkComponent {...instagramLink}>
                      <InstagramIcon />
                    </LinkComponent>
                  </li>
                )}
                {yelpLink.href && (
                  <li>
                    <LinkComponent {...yelpLink}>
                      <YelpIcon />
                    </LinkComponent>
                  </li>
                )}
                {tripadvisorLink.href && (
                  <li>
                    <LinkComponent {...tripadvisorLink}>
                      <TripadvisorIcon />
                    </LinkComponent>
                  </li>
                )}
              </IconGroup>
            </SocialContainer>
          </FlexCol>
        </StyledCol>

        <StyledCol
          xxs={12}
          xs={12}
          sm={12}
          md={12}
          lg={3.5}
          xl={2}
          {...applOrder}
        >
          <FlexCol>
            {isLarge && contactBlock}

            <AppContainer>
              {(iosLink.href || androidLink.href) && (
                <Text.h6 white>{appLabel}</Text.h6>
              )}

              <IconGroup>
                {iosLink.href && (
                  <li>
                    <LinkComponent {...iosLink}>
                      <AppIcon>
                        <IOSIcon />
                        <Text {...textStyle}>iOS</Text>
                      </AppIcon>
                    </LinkComponent>
                  </li>
                )}
                {androidLink.href && (
                  <li>
                    <LinkComponent {...androidLink}>
                      <AppIcon>
                        <AndroidIcon />
                        <Text {...textStyle}>Android</Text>
                      </AppIcon>
                    </LinkComponent>
                  </li>
                )}
              </IconGroup>
            </AppContainer>
          </FlexCol>
        </StyledCol>

        <StyledCol {...capSizes} />
      </StyledRow>
      {!isXLarge && copyrightText && (
        <CopyrightSm center size="xxs" color="secondaryLightBlue">
          {ReactHtmlParser(copyrightText)}
        </CopyrightSm>
      )}

      {bannerText && (
        <Banner
          bold
          white
          center
          size={fontSizesSmall.xs}
          lg="sm"
          uppercase
          spacing="1.2px"
        >
          {ReactHtmlParser(bannerText)}
        </Banner>
      )}
    </Container>
  )
}

Footer.propTypes = {
  address: PropTypes.object,
  address_label: PropTypes.object,
  app_links: PropTypes.array,
  app_title: PropTypes.object,
  banner_text: PropTypes.object,
  contact_links: PropTypes.array,
  contact_title: PropTypes.object,
  copyright: PropTypes.object,
  links: PropTypes.array,
  logo: PropTypes.object,
  site_links: PropTypes.array,
  site_links_title: PropTypes.object,
  title: PropTypes.object,
  phone_link: PropTypes.object,
  chat_link: PropTypes.object,
  facebook_link: PropTypes.object,
  twitter_link: PropTypes.object,
  instagram_link: PropTypes.object,
  yelp_link: PropTypes.object,
  trip_advisor: PropTypes.object,
  ios_link: PropTypes.object,
  android_link: PropTypes.object,
}

export default Footer

const AppContainer = styled.div`
  ${({ theme }) => css`
    ${theme.media.lg`
      transform: translateY(-5px);
    `};
  `};
`

const SocialContainer = styled.div`
  ${({ theme }) => css`
    ${theme.media.lg`
      transform: translateY(-2px);
    `};
  `};
`

const Logo = styled.img`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${rem(123)};
    margin-bottom: ${theme.space(7)};
  `};
`

const CopyrightSm = styled(Text)`
  ${({ theme }) => css`
    padding: 0 ${theme.space(1)};
    margin: ${theme.space(2)} 0;

    > * {
      display: inline;
    }
  `};
`

const Container = styled.footer`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    min-height: 200px;

    background-color: ${theme.color.blue};
    background-image: linear-gradient(145deg, #3e6594 0%, #1d355b 50%);

    z-index: 5;

    a {
      cursor: pointer;

      * {
        transition: color 0.3s;
      }

      svg {
        transition: opacity 0.3s;
      }

      &:hover {
        * {
          color: #fff;
        }

        svg {
          opacity: 0.7;
        }
      }
    }

    > div {
      width: 100%;
    }
  `};
`

const StyledRow = styled(Row)`
  ${({ theme }) => css`
    padding: ${theme.space(4)} ${rem(20)};

    max-width: ${theme.layout.maxWidth};

    ${theme.media.lg`
     padding: ${theme.space(9)} ${rem(20)};
    `};

    ${theme.media.xl`
     padding: ${theme.space(9)} 0;
    `};
  `};
`

const StyledCol = styled(Col)`
  ${({ theme }) => css`
    ${theme.media.lg`
      padding-right: ${theme.space(2)} !important;
    `};

    ul {
      list-style: none;
    }

    > div:first-child h6,
    > h6:first-child {
      margin-top: ${theme.space(4)};

      ${theme.media.lg`
        margin-top: 0;
      `};
    }

    > div:first-child > h6 {
      ${theme.media.lg`
        margin-top: 0;
      `};
    }

    h6 {
      margin: ${theme.space(6)} 0 ${theme.space(1)};

      ${theme.media.lg`
        margin: ${theme.space(6)} 0 ${theme.space(1.5)};
      `};
    }

    .address-label {
      margin-top: 0 !important;
    }

    p,
    h6 {
      text-align: center;

      ${theme.media.lg`
        text-align: left;
      `};
    }

    > div {
      img,
      svg {
        width: auto;
        height: 22px;
        object-fit: contain;
      }
    }
  `};
`

const IconGroup = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    svg {
      margin: 0 ${theme.space(1.5)};
      height: ${rem(22)};
      width: auto;
      ${theme.media.lg`
        margin-left: 0;
        margin-right: ${theme.space(1.5)};
        margin-bottom: ${theme.space(1)};
      `};
    }

    ${theme.media.lg`
      justify-content: flex-start;
      flex-wrap: wrap;
    `};
  `};
`

const AppIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 ${theme.space(1.5)};

    img,
    svg {
      margin-bottom: ${theme.space(0.5)};

      ${theme.media.lg`
        margin-right: ${theme.space(1)};
        margin-bottom: ${theme.space(1)};
    `};
    }

    ${theme.media.lg`
      margin: 0;
      flex-direction: row;
      padding-right: ${theme.space(3)};
    `};
  `};
`

const ContactIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.space(1)} !important;

    p {
      width: 100%;
    }

    svg {
      display: none;
      margin-right: ${theme.space(1)};

      ${theme.media.lg`
        display: block;
      `};
    }

    ${theme.media.lg`
        flex-direction: row;
         justify-content: flex-start;
      `};
  `};
`

const Banner = styled(Text)`
  ${({ theme }) => css`
    padding: ${theme.space(1)};
    background-color: ${theme.color.secondaryBlue};
  `};
`

const SiteLinks = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${theme.space(0)} ${theme.space(1)};

    li {
      margin-bottom: ${rem(10)};

      ${theme.media.lg`
        padding-right: ${theme.space(1)};
        margin-bottom: 0;
    `};
    }

    ${theme.media.lg`
       grid-template-columns: 1fr 1fr;
    `};
  `};
`

const FlexCol = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    ${theme.media.lg`
      min-height: ${rem(170)};
    `};

    ${theme.media.xl`
      min-height: none;
    `};
  `};
`
