import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import useListenForAccessibility from '../hooks/useListenForAccessibility'

import { breakpointsPx } from '../style/theme'
import useMedia from 'use-media'

/*
    Example usage:
    const { focusStyleOn } = useAppContext()
*/

export const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const [menuModalOpen, setMenuModalOpen] = useState(false)
  const [isScrollingLocked, setScrollingLocked] = useState(false)
  const [propertiesModalOpen, setPropertiesModalOpen] = useState(false)
  const [showAllSearchResults, setShowAllSearchResults] = useState(false)
  const [currentSearchResults, setCurrentSearchResults] = useState([])
  const [currentSearch, setCurrentSearch] = useState('')
  const [siteInit, setSiteInit] = useState(false)
  const [showNavSearch, setShowNavSearch] = useState(false)

  // Listen for keyevents to turn on focus styles
  const focusStyleOn = useListenForAccessibility()

  // Breakpoints
  const isMedium = useMedia(`(min-width: ${breakpointsPx.md}px)`)
  const isLarge = useMedia(`(min-width: ${breakpointsPx.lg}px)`)
  const isXLarge = useMedia(`(min-width: ${breakpointsPx.xl}px)`)
  const isXXLarge = useMedia(`(min-width: ${breakpointsPx.xxl}px)`)

  const value = useMemo(
    () => ({
      showNavSearch,
      setShowNavSearch,
      focusStyleOn,
      isMedium,
      isLarge,
      isXLarge,
      isXXLarge,
      menuModalOpen,
      propertiesModalOpen,
      setPropertiesModalOpen,
      setMenuModalOpen,
      isScrollingLocked,
      setScrollingLocked,
      showAllSearchResults,
      setShowAllSearchResults,
      currentSearchResults,
      setCurrentSearchResults,
      currentSearch,
      setCurrentSearch,
      siteInit,
      setSiteInit,
    }),
    [
      showNavSearch,
      focusStyleOn,
      isMedium,
      isLarge,
      isXLarge,
      isXXLarge,
      menuModalOpen,
      propertiesModalOpen,
      isScrollingLocked,
      showAllSearchResults,
      currentSearchResults,
      currentSearch,
      siteInit,
    ],
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
