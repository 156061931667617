import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useSearch, { SEARCH_STATUS_COMPLETE } from '../../../hooks/useSearch'
import { SANS_SERIF, WEIGHT } from '../../../style/type'

import Text, { setFontSize, sizes } from '../../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'

import Results from '../search/Results'

const SearchForm = ({ placeholder, ...props }) => {
  const inputRef = useRef()
  const [search, setSearch] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [newInput, setNewInput] = useState(false)
  const { searchResult, searchStatus } = useSearch(search)

  const handleReset = () => {
    setSearch('')
  }

  const handleChange = () => {
    setSearch(inputRef.current.value)
    if (!newInput) setNewInput(true)
  }

  useEffect(() => {
    if (setNewInput && searchStatus === SEARCH_STATUS_COMPLETE)
      setNewInput(false)
  }, [setNewInput, searchStatus])

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus()
  }, [])

  useEffect(() => {
    if (search.length) setShowSearch(true)
  }, [search.length])

  return (
    <Form>
      <input
        ref={inputRef}
        id="search"
        placeholder={placeholder}
        type="search"
        value={search}
        onChange={handleChange}
      />
      {search && search.length && (
        <ClearButton aria-label="Clear" type="button" onClick={handleReset}>
          <Text size="xxs" lg="xs" color="blue">
            Clear
          </Text>
        </ClearButton>
      )}
      {!!search.length && showSearch && (
        <Results
          results={searchResult}
          status={searchStatus}
          search={search}
          setShowSearch={setShowSearch}
          newInput={newInput}
        />
      )}
    </Form>
  )
}

SearchForm.defaultProps = {
  placeholder: 'What are you looking for?',
}

SearchForm.propTypes = {
  placeholder: PropTypes.string,
}

export default SearchForm

const HEIGHT = 42

export const Form = styled.div`
  ${({ hasError, theme }) => css`
    ${theme.mixin.fadeIn()};

    position: relative;
    width: 100%;
    max-width: ${rem(690)};

    ${theme.media.xl`
      max-width: ${rem(630)};
    `};

    @media (min-width: 1060px) {
      max-width: ${rem(690)};
    }

    input {
      display: flex;
      align-items: center;

      width: 100%;
      height: ${rem(HEIGHT)};
      border-radius: ${rem(HEIGHT / 2)};

      padding: 0 ${theme.space(2)} 0 ${theme.space(3)};

      color: ${theme.color.neutralBlue};
      background-color: #ffffff;

      font-family: ${SANS_SERIF};
      ${setFontSize(sizes.md)};
      font-weight: ${WEIGHT.MEDIUM};

      ${theme.media.lg`
        padding: 0 ${theme.space(2)};
      `};

      ::-webkit-search-cancel-button {
        display: none;
      }
      ::-ms-clear {
        display: none;
      }
    }
  `};
`

const ClearButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    right: ${rem(13)};
    opacity: 0.5;

    ${theme.media.lg`
      right: ${theme.space(2)};
    `};

    transform: translateY(-50%);
    transition: opacity 0.4s ease-in;
    cursor: pointer;

    &:hover {
      transition: opacity 0.3s ease-out;
      opacity: 1;
    }
  `};
`
