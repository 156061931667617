import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import Text, { setFontSize, sizes } from './Text'

import styled, { css } from 'styled-components'
import { rem, lighten, transparentize } from 'polished'
import { useAppContext } from '../../context/AppContext'
import { isExternal } from './Link'
import theme, { fontSizesSmall } from '../../style/theme'

const Button = ({
  className,
  children,
  disabled,
  href,
  rel,
  size,
  target,
  onClick,
  outline,
  small,
  underline,
  ...props
}) => {
  const { focusStyleOn } = useAppContext()

  const hrefClean = !href ? '#' : href

  const disabledColor =
    (outline && outline.enabled) || underline
      ? 'blue'
      : 'rgba(255, 255, 255, 0.5)'

  let outlineProps = {}
  if (outline && typeof outline === 'string')
    outlineProps = { enabled: true, color: outline }
  else if (outline) outlineProps = { enabled: true, color: theme.color.blue }

  const content = (
    <>
      <Text
        medium={underline}
        bold={!underline}
        size={size}
        nowrap
        color={disabled ? disabledColor : 'blue'}
        lineHeight={rem(20)}
      >
        {children}
      </Text>
    </>
  )

  const sharedProps = {
    className,
    disabled,
    onClick: disabled ? null : onClick,
    outline: outlineProps,
    showFocus: focusStyleOn,
    underline,
    small,
    ...props,
  }

  const renderLink = () => {
    return isExternal(hrefClean) ? (
      <StyledLink href={hrefClean} target={target} rel={rel} {...sharedProps}>
        {content}
      </StyledLink>
    ) : (
      <StyledGatsbyLink to={hrefClean} {...sharedProps}>
        {content}
      </StyledGatsbyLink>
    )
  }

  return href ? renderLink() : <Btn {...sharedProps}>{content}</Btn>
}

Button.defaultProps = {
  size: 'xs',
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  inline: PropTypes.bool,
  target: PropTypes.string,
  rel: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  outline: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  small: PropTypes.bool,
  underline: PropTypes.bool,
}

export default Button

export const StyledButton = styled(Button)``

export const buttonStyle = ({
  disabled,
  inline,
  outline,
  theme,
  underline,
  fullWidth,
  small,
}) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${underline ? 'auto' : rem(41)};
  min-height: ${underline ? 'none' : rem(41)};
  max-width: ${fullWidth ? '100%' : rem(355)};

  flex: 1;
  flex-shrink: 0;

  padding: 0 ${theme.space(1)};

  border: 2px solid transparent;
  border-radius: ${theme.layout.borderRadius};
  background-color: ${theme.color.yellow};

  color: ${theme.color.blue};
  text-transform: uppercase;
  text-decoration: none;

  overflow: hidden;
  cursor: pointer;
  transition: opacity 0.3s, background-color 0.3s, color 0.3s;

  > div {
    ${setFontSize(sizes.xxs)};
    letter-spacing: ${rem(0.5)};
    padding: 0px 1rem;
    pointer-events: none;
  }

  &:hover {
    ${!disabled &&
      !underline &&
      css`
        ${Text} {
          color: ${theme.color.yellow};
        }

        border: 2px solid ${theme.color.yellow};
        background-color: ${theme.color.blue};
      `};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${theme.media.lg`
    flex: none;
  `};

  ${theme.mixin.afterFocus};

  ${inline &&
    css`
      display: inline-flex;
      flex: 0;
      width: auto;
    `};

  ${underline &&
    css`
      display: inline-flex;
      flex: 1;
      width: auto;
      background-color: transparent;
      padding: 0;
      justify-content: flex-start;

      > div {
        ${setFontSize(sizes.xs)};
        letter-spacing: ${rem(1)};
      }

      &:hover {
        background-color: transparent;

        > div:after {
          background-color: ${theme.color.secondaryBlue};
        }
      }

      > div {
        height: auto;
        transform: translateY(-1px);
        color: ${theme.color.secondaryBlue};
        padding: 0px;

        &:after {
          position: absolute;
          display: block;
          content: ' ';
          width: 100%;
          height: 2px;
          left: 0;
          bottom: 0;
          background-color: ${theme.color.secondaryLightBlue};
          transition: background-color 0.3s ease-out;
        }
      }
    `};

  ${outline &&
    outline.enabled &&
    css`
      background-color: transparent;
      border: 2px solid ${outline.color};
      ${Text} {
        color: ${outline.color};
      }

      &:hover {
        ${!disabled &&
          css`
            ${Text} {
              color: ${outline.color === 'white' ? theme.color.blue : '#fff'};
            }

            > div {
              color: ${theme.color.blue};
            }

            border: 2px solid ${theme.color.blue};
            background-color: ${outline.color};
          `};
      }
    `}

  ${small &&
    css`
      padding: 0;
      min-height: auto;
      height: auto;

      > div {
        padding: 0 8px;
      }
    `};

  ${disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      background-color: #767676;

      ${((outline && outline.enabled) || underline) &&
        css`
          background-color: transparent;
        `};
    `};
`

const Btn = styled.button`
  ${buttonStyle};
`

const StyledLink = styled.a`
  ${buttonStyle};
`

const StyledGatsbyLink = styled(Link)`
  ${buttonStyle};
`
