import { useState, useEffect } from 'react'
import { throttle } from 'lodash'

const isClient = () => typeof window !== 'undefined'

function getSize() {
  if (!isClient()) return false

  const width =
    Math.max(document.documentElement.clientWidth, window.innerWidth) || 0
  const height =
    Math.max(document.documentElement.clientHeight, window.innerHeight) || 0

  return {
    width,
    height,
  }
}

export default function() {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient()) return false

    function resize() {
      setWindowSize(getSize())
    }

    const handleResize = throttle(resize, 200)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
