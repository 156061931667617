import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Text from '../../common/Text'
import { PrevNextArrow } from '../../common/Icons'
import HTMLParser from 'react-html-parser'
import Button from '../../common/Button'
import {
  SEARCH_STATUS_COMPLETE,
  SEARCH_STATUS_SEARCHING,
  SEARCH_STATUS_SHORT,
} from '../../../hooks/useSearch'
import Loader from 'react-loader-spinner'
import LinkComponent from '../../common/Link'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import theme from '../../../style/theme'
import { useAppContext } from '../../../context/AppContext'

const ErrorResult = ({ header, body }) => {
  const { isLarge } = useAppContext()

  return (
    <Text html center css={{ marginTop: rem(isLarge ? 40 : 30) }}>
      <h5>{header}</h5>
      <p style={{ marginTop: rem(10) }}>{body}</p>
    </Text>
  )
}

ErrorResult.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
}

const SearchResult = ({ fields, setShowSearch, ...props }) => {
  const { isLarge, setShowNavSearch } = useAppContext()

  const handleClick = useCallback(() => {
    setShowSearch(false)
    setShowNavSearch(false)
  }, [setShowSearch])

  return (
    <SearchResultContainer {...props}>
      <LinkComponent href={fields.path} onClick={handleClick}>
        <Row>
          <TextGroup>
            <Text.h5 spacing={1.2} lineHeightMultiplier={1.4}>
              {fields.title}
            </Text.h5>
            {isLarge && <Text.p>{HTMLParser(fields.excerpt)}</Text.p>}
          </TextGroup>
          <Icon>
            <PrevNextArrow width={20} height={20} />
          </Icon>
        </Row>
      </LinkComponent>
    </SearchResultContainer>
  )
}

SearchResult.propTypes = {
  fields: PropTypes.object,
  setShowSearch: PropTypes.func,
}

const ShortResults = ({
  newInput,
  results,
  toggleShowAll,
  status,
  setShowSearch,
}) => {
  const resultsShort = results.slice(0, 3)

  return (
    <Container center={status === SEARCH_STATUS_SEARCHING}>
      {resultsShort.map(({ fields, id }, i) => (
        <SearchResult
          key={id + i}
          fields={fields}
          i={i}
          setShowSearch={setShowSearch}
        />
      ))}

      {!newInput &&
        status === SEARCH_STATUS_COMPLETE &&
        resultsShort.length === 0 && (
          <ErrorResult
            header="No Results"
            body="Please try different search terms."
          />
        )}
      {status === SEARCH_STATUS_SHORT && (
        <ErrorResult
          header="Query Too Short"
          body="Search terms should be at least 3 characters long."
        />
      )}

      {status === SEARCH_STATUS_SEARCHING && (
        <StyledLoader
          type="Oval"
          color={theme.color.blue}
          height={50}
          width={50}
        />
      )}

      {results.length > 3 && (
        <ButtonContainer>
          <Button onClick={toggleShowAll}>
            VIEW ALL {results.length} RESULTS
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
}

ShortResults.propTypes = {
  newInput: PropTypes.bool,
  results: PropTypes.array,
  toggleShowAll: PropTypes.func,
  setShowSearch: PropTypes.func,
  status: PropTypes.string,
}

export default ShortResults

const StyledLoader = styled(Loader)`
  ${({ theme }) => css`
    margin-top: 200px; //Arbitrary
  `}
`

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    position: relative;
  `}
`

const TextGroup = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding-right: ${theme.space(3)};
  `}
`

const Icon = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: ${theme.space(2)};

    svg {
      path {
        fill: ${theme.color.secondaryLightBlue};
      }

      ${theme.mixin.opacityHover};
    }
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    padding: 0 ${theme.space(1)} 0;

    ${theme.media.lg`
      padding:0;
    `}
  `}
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${rem(690)};
    ${theme.mixin.fadeIn(3 * 0.15)};

    > * {
      width: 100%;
      max-width: none;
    }
  `}
`

const SearchResultContainer = styled.div`
  ${({ theme, i }) => css`
    ${theme.mixin.fadeIn(i * 0.15)};
    position: relative;
    max-width: ${rem(690)};
    padding: ${theme.space(3)} ${theme.space(2)} ${theme.space(3)}
      ${theme.space(3)};
    margin: 0 0 ${theme.space(1)};

    background: #fff;
    border-radius: ${theme.layout.borderRadius};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    width: 100%;

    transition-delay: 0.5s, 0.1s, 0.1s, 0s, 0s, 0s;
    transition-property: opacity, transform, filter, background-color, color,
      box-shadow;
    transition-duration: 1.6s, 1.4s, 0.1s, 0.7s, 0.7s, 0.7s;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1),
      cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1);

    svg * {
      transition: 0.3s fill;
      transition-duration: 1.6s, 1.4s, 0.1s, 0.7s, 0.7s, 0.7s;
    }

    &:hover {
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
      transform: translateY(1px) scale(0.99);

      svg * {
        fill: ${theme.color.blue};
      }
    }

    ${theme.media.lg`
      min-width: ${rem(690)};
      padding: ${theme.space(4)} ${theme.space(6)};

    ${theme.space(5)};
      margin-bottom: ${theme.space(2)};
    `};

    > p {
      margin-top: ${theme.space(1.5)};
    }

    h5 {
      ${theme.mixin.opacityHover};

      ${theme.media.lg`
        margin-bottom: ${theme.space(1)};\
      `};
    }
  `}
`
