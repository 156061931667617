import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { GATSBY_CLOUDSEARCH_SEARCH_ENDPOINT } from '../../lib/constants/'
import { get, debounce } from 'lodash'

export const SEARCH_STATUS_COMPLETE = 'searchStatusComplete'
export const SEARCH_STATUS_SEARCHING = 'searchStatusSearching'
export const SEARCH_STATUS_START = 'searchStatusStart'
export const SEARCH_STATUS_ERROR = 'searchStatusError'
export const SEARCH_STATUS_SHORT = 'searchStatusShort'

const FRAGMENT_LENGTH = 75

const createExcerpt = (text = '', match = '') => {
  const terms = match.split(' ')

  const matchIndexes = terms.map(term =>
    text.toLowerCase().indexOf(term.toLowerCase()),
  )

  const matches = matchIndexes.filter(i => i > -1)
  if (!!matches.length) {
    const matchIndex = matches[0]
    const beforeFrag = text.slice(
      matchIndex > FRAGMENT_LENGTH ? matchIndex - FRAGMENT_LENGTH : 0,
      matchIndex,
    )
    const afterFrag = text.slice(matchIndex, matchIndex + FRAGMENT_LENGTH)
    const pattern = `(${terms.join('|')})`
    const excerpt = beforeFrag + afterFrag
    return excerpt.replace(new RegExp(pattern, 'gi'), '<b>$1</b>')
  } else {
    return text.slice(0, FRAGMENT_LENGTH * 2)
  }
}

const searchFunc = async (query, setStatus, setResult) => {
  try {
    setStatus(SEARCH_STATUS_SEARCHING)
    setResult([])
    const q = `${query}`
    const parser = 'simple'
    const options = {
      fields: ['title^5', 'body', 'url'],
    }
    const { data } = await axios.get(GATSBY_CLOUDSEARCH_SEARCH_ENDPOINT, {
      params: {
        q,
        'q.parser': parser,
        'q.options': options,
        sort: '_score desc, event_date desc, publish_date desc',
        size: 999,
        start: 0,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'no-cors',
    })

    setResult(get(data, 'hits.hit', []))
    setStatus(SEARCH_STATUS_COMPLETE)
  } catch (e) {
    setStatus(SEARCH_STATUS_ERROR)
  }
}

const queryShortFunc = setSearchStatus => {
  setSearchStatus(SEARCH_STATUS_SHORT)
}

const delaySearch = debounce(searchFunc, 750, {
  trailing: true,
  leading: false,
})

const delayShort = debounce(queryShortFunc, 750, {
  trailing: true,
  leading: false,
})

const useSearch = query => {
  const [searchResult, setSearchResult] = useState([])
  const [searchStatus, setSearchStatus] = useState(SEARCH_STATUS_START)

  useEffect(() => {
    //setSearchResult([])
    if (query.length > 2) {
      delayShort.cancel()
      delaySearch(query, setSearchStatus, setSearchResult)
    } else {
      setSearchStatus(SEARCH_STATUS_START)
      delayShort(setSearchStatus)
      setSearchResult([])
    }
  }, [query])

  const resultsWithExcerpts = useMemo(
    () =>
      searchResult.map(result => ({
        ...result,
        fields: {
          ...result.fields,
          excerpt: createExcerpt(result.fields.body, query),
        },
      })),
    [query, searchResult],
  )

  return {
    searchResult: [...resultsWithExcerpts],
    searchStatus,
  }
}

export default useSearch
