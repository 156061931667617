import { get } from 'lodash'
import { useMemo } from 'react'

export default data => {
  return useMemo(
    () => ({
      components: get(data, 'data'),
      sections: {
        footer: get(data, 'footer'),
        header: {
          nav: get(data, 'navigation'),
          topNavBar: get(data, 'topNavBar'),
          properties: get(data, 'properties'),
        },
      },
      social: {
        instagram: get(data, 'instagram'),
        twitter: get(data, 'twitter'),
      },
    }),
    [data],
  )
}
