import Airplane from '../../assets/icons/airplane.inline.svg'
import AsianGaming from '../../assets/icons/asiangaming.inline.svg'
import Bag from '../../assets/icons/bag.inline.svg'
import Ballot from '../../assets/icons/ballot.inline.svg'
import Bill from '../../assets/icons/bill.inline.svg'
import Boat from '../../assets/icons/boat.inline.svg'
import Cake from '../../assets/icons/cake.inline.svg'
import Camera from '../../assets/icons/camera.inline.svg'
import Car from '../../assets/icons/car.inline.svg'
import Cards from '../../assets/icons/cards.inline.svg'
import Cart from '../../assets/icons/cart.inline.svg'
import Cheque from '../../assets/icons/cheque.inline.svg'
import Cocktail from '../../assets/icons/cocktail.inline.svg'
import Coins from '../../assets/icons/coins.inline.svg'
import Concierge from '../../assets/icons/concierge.inline.svg'
import Device from '../../assets/icons/device.inline.svg'
import Dice from '../../assets/icons/dice.inline.svg'
import Dining from '../../assets/icons/dining.inline.svg'
import Door from '../../assets/icons/door.inline.svg'
import Download from '../../assets/icons/download.inline.svg'
import Food from '../../assets/icons/food.inline.svg'
import Gift from '../../assets/icons/gift.inline.svg'
import Id from '../../assets/icons/id.inline.svg'
import Keys from '../../assets/icons/keys.inline.svg'
import Letter from '../../assets/icons/letter.inline.svg'
import Limo from '../../assets/icons/limo.inline.svg'
import Mailbox from '../../assets/icons/mailbox.inline.svg'
import Mic from '../../assets/icons/mic.inline.svg'
import Bingo from '../../assets/icons/bingo.inline.svg'
import Pc from '../../assets/icons/pc.inline.svg'
import Poker from '../../assets/icons/poker.inline.svg'
import Popsicle from '../../assets/icons/popsicle.inline.svg'
import Trivia from '../../assets/icons/trivia.inline.svg'
import Reset from '../../assets/icons/reset.inline.svg'
import Rope from '../../assets/icons/rope.inline.svg'
import RushRewards from '../../assets/icons/rushrewards.inline.svg'
import Seat from '../../assets/icons/seat.inline.svg'
import Slots from '../../assets/icons/slots.inline.svg'
import SocialPromotions from '../../assets/icons/socialpromotions.inline.svg'
import Sportsbook from '../../assets/icons/sportsbook.inline.svg'
import Stars from '../../assets/icons/stars.inline.svg'
import Strikes from '../../assets/icons/strikes.inline.svg'
import Ticket from '../../assets/icons/ticket.inline.svg'
import Toque from '../../assets/icons/toque.inline.svg'
import Trophy from '../../assets/icons/trophy.inline.svg'

import PlayIcon from '../../assets/icons/play.inline.svg'
import PauseIcon from '../../assets/icons/pause.inline.svg'

import Chat from '../../assets/icons/chat.inline.svg'
import Phone from '../../assets/icons/phone.inline.svg'
import Facebook from '../../assets/icons/facebook.inline.svg'
import Twitter from '../../assets/icons/twitter.inline.svg'
import X from '../../assets/icons/x.inline.svg'
import Instagram from '../../assets/icons/instagram.inline.svg'
import Yelp from '../../assets/icons/yelp.inline.svg'
import Tripadvisor from '../../assets/icons/tripadvisor.inline.svg'

import Android from '../../assets/icons/android.inline.svg'
import IOS from '../../assets/icons/ios.inline.svg'

import { ALL_ICON_TYPES } from '../../../lib/constants'
import { css } from 'styled-components'
import { rem } from 'polished'

export const iconCss = color => css`
  ${({ theme }) => css`
    svg {
      ${theme.media.lg`
        &#rr-svg {
          height: ${rem(20)}
        }

        &#ballot-svg {
          height: ${rem(25)}
        }
      `};

      height: 100%;
      * {
        &.use-fill-only {
          fill: ${color};
          stroke: transparent;
        }
        &.use-fill {
          fill: ${color};
        }
        stroke: ${color};
      }
    }
  `}
`
export const ICON_ATLAS = {
  [ALL_ICON_TYPES.ICON_AIRPLANE]: Airplane,
  [ALL_ICON_TYPES.ICON_ASIAN_GAMING]: AsianGaming,
  [ALL_ICON_TYPES.ICON_BAG]: Bag,
  [ALL_ICON_TYPES.ICON_BALLOT]: Ballot,
  [ALL_ICON_TYPES.ICON_BILL]: Bill,
  [ALL_ICON_TYPES.ICON_BOAT]: Boat,
  [ALL_ICON_TYPES.ICON_CAKE]: Cake,
  [ALL_ICON_TYPES.ICON_CAMERA]: Camera,
  [ALL_ICON_TYPES.ICON_CAR]: Car,
  [ALL_ICON_TYPES.ICON_CARDS]: Cards,
  [ALL_ICON_TYPES.ICON_CART]: Cart,
  [ALL_ICON_TYPES.ICON_CHEQUE]: Cheque,
  [ALL_ICON_TYPES.ICON_COCKTAIL]: Cocktail,
  [ALL_ICON_TYPES.ICON_COINS]: Coins,
  [ALL_ICON_TYPES.ICON_CONCIERGE]: Concierge,
  [ALL_ICON_TYPES.ICON_DEVICE]: Device,
  [ALL_ICON_TYPES.ICON_DICE]: Dice,
  [ALL_ICON_TYPES.ICON_DINING]: Dining,
  [ALL_ICON_TYPES.ICON_DOOR]: Door,
  [ALL_ICON_TYPES.ICON_DOWNLOAD]: Download,
  [ALL_ICON_TYPES.ICON_FOOD]: Food,
  [ALL_ICON_TYPES.ICON_GIFT]: Gift,
  [ALL_ICON_TYPES.ICON_ID]: Id,
  [ALL_ICON_TYPES.ICON_KEYS]: Keys,
  [ALL_ICON_TYPES.ICON_LETTER]: Letter,
  [ALL_ICON_TYPES.ICON_LIMO]: Limo,
  [ALL_ICON_TYPES.ICON_MAILBOX]: Mailbox,
  [ALL_ICON_TYPES.ICON_MIC]: Mic,
  [ALL_ICON_TYPES.ICON_BINGO]: Bingo,
  [ALL_ICON_TYPES.ICON_PC]: Pc,
  [ALL_ICON_TYPES.ICON_POKER]: Poker,
  [ALL_ICON_TYPES.ICON_POPSICLE]: Popsicle,
  [ALL_ICON_TYPES.ICON_QUESTIONS]: Trivia,
  [ALL_ICON_TYPES.ICON_RESET]: Reset,
  [ALL_ICON_TYPES.ICON_ROPE]: Rope,
  [ALL_ICON_TYPES.ICON_RUSHREWARDS]: RushRewards,
  [ALL_ICON_TYPES.ICON_SEAT]: Seat,
  [ALL_ICON_TYPES.ICON_SLOTS]: Slots,
  [ALL_ICON_TYPES.ICON_SOCIAL_PROMOTIONS]: SocialPromotions,
  [ALL_ICON_TYPES.ICON_SPORTSBOOK]: Sportsbook,
  [ALL_ICON_TYPES.ICON_STARS]: Stars,
  [ALL_ICON_TYPES.ICON_STRIKES]: Strikes,
  [ALL_ICON_TYPES.ICON_TICKET]: Ticket,
  [ALL_ICON_TYPES.ICON_TOQUE]: Toque,
  [ALL_ICON_TYPES.ICON_TROPHY]: Trophy,

  [ALL_ICON_TYPES.PROMO_CATEGORY_MONTHLY_PROMO]: Stars,
  [ALL_ICON_TYPES.PROMO_CATEGORY_SLOT_PROMO]: Slots,
  [ALL_ICON_TYPES.PROMO_CATEGORY_SLOT_TOURN]: Slots,
  [ALL_ICON_TYPES.PROMO_CATEGORY_CELEBRITY]: Door,
  [ALL_ICON_TYPES.PROMO_CATEGORY_FOOD]: Dining,
  [ALL_ICON_TYPES.PROMO_CATEGORY_TABLE_GAMES_PROMO]: Dice,
  [ALL_ICON_TYPES.PROMO_CATEGORY_TABLE_GAMES_TOURN]: Dice,
  [ALL_ICON_TYPES.PROMO_CATEGORY_VIP_PROMO]: Rope,
  [ALL_ICON_TYPES.PROMO_CATEGORY_CRUISE]: Boat,
  [ALL_ICON_TYPES.PROMO_CATEGORY_POKER_PROMO]: Cards,
  [ALL_ICON_TYPES.PROMO_CATEGORY_POKER_TOURN]: Cards,
  [ALL_ICON_TYPES.PROMO_CATEGORY_BLACKJACK_PROMO]: Cards,
  [ALL_ICON_TYPES.PROMO_CATEGORY_BLACKJACK_TOURN]: Cards,
  [ALL_ICON_TYPES.PROMO_CATEGORY_GIFT]: Gift,
  [ALL_ICON_TYPES.PROMO_CATEGORY_JACKPOT_PARTY]: Coins,
  [ALL_ICON_TYPES.PROMO_CATEGORY_JACKPOT_DRAWING]: Ballot,
  [ALL_ICON_TYPES.PROMO_CATEGORY_UNITED_POINTS]: Airplane,
  [ALL_ICON_TYPES.PROMO_CATEGORY_TRIP]: Bag,
  [ALL_ICON_TYPES.PROMO_CATEGORY_NEW_MEMBER]: RushRewards,
  [ALL_ICON_TYPES.PROMO_CATEGORY_BINGO_PROMO]: Bingo,
  [ALL_ICON_TYPES.PROMO_CATEGORY_CAR_GIVEAWAY]: Car,
  [ALL_ICON_TYPES.PROMO_CATEGORY_BIRTHDAY]: Cake,
  [ALL_ICON_TYPES.PROMO_CATEGORY_SPORTSBOOK]: Sportsbook,
  [ALL_ICON_TYPES.PROMO_CATEGORY_ASIAN_GAMING]: AsianGaming,
  [ALL_ICON_TYPES.PROMO_CATEGORY_SOCIAL_PROMOTIONS]: SocialPromotions,

  [ALL_ICON_TYPES.EVENT_CATEGORY_ENTERTAINER]: Mic,
  [ALL_ICON_TYPES.EVENT_CATEGORY_FOOD]: Dining,
  [ALL_ICON_TYPES.EVENT_CATEGORY_TICKETED]: Ticket,
  [ALL_ICON_TYPES.EVENT_CATEGORY_TRIVIA]: Trivia,

  Chat,
  Phone,

  Facebook,
  Twitter,
  X,
  Instagram,
  Yelp,
  Tripadvisor,

  PlayIcon,
  PauseIcon,

  Android,
  IOS,
}
