import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import Button from '../../common/Button'
import Link from '../../common/Link'
import Modal from '../../common/Modal'
import ReactHtmlParser from 'react-html-parser'
import { SmallArrow } from '../../common/Icons'
import Text from '../../common/Text'
import { useAppContext } from '../../../context/AppContext'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { formatGoogleMapsLink } from '../../../utils/formatText'
import useWindowSize from '../../../hooks/useWindowSize'

const PropertiesSmall = ({ address, backLabel, hereLabel, label, list }) => {
  const {
    isXLarge,
    propertiesModalOpen,
    setPropertiesModalOpen,
  } = useAppContext()
  const { height } = useWindowSize()
  const modal = useRef()
  const [showModal, setModal] = useState(false)
  const toggleModal = () => setModal(!showModal)
  const propertyAddress = get(address, 'html')
  const propertyAddressText = formatGoogleMapsLink(get(address, 'text'))

  const closeModal = useCallback(() => {
    const close = get(modal, 'current.close')
    if (close) close()
    if (propertiesModalOpen) setPropertiesModalOpen(false)
  }, [modal, propertiesModalOpen, setPropertiesModalOpen])

  useEffect(() => {
    if ((isXLarge && showModal) || !showModal) {
      closeModal()
    }
  }, [closeModal, showModal, isXLarge])

  return (
    <>
      <AddressLink
        href={propertyAddressText}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text color="secondaryBlue" size="xs" lineHeight="17px" medium>
          {ReactHtmlParser(propertyAddress)}
        </Text>
        <SmallArrow width={20} height={20} fill="secondaryLightBlue" />
      </AddressLink>
      {label && (
        <Button outline onClick={toggleModal} aria-label={label}>
          {label}
        </Button>
      )}
      <Modal
        targetId="fixed-portal"
        ref={modal}
        show={showModal}
        hideModal={() => setModal(false)}
        onClose={() => setModal(false)}
        onTransitionInComplete={() => setPropertiesModalOpen(true)}
        zIndex={99999999999999}
      >
        <Content style={{ height: `${height}px` }}>
          <List>
            {list.map(({ name, link, location, ...props }, i) => {
              const active = i === 0
              const linksProps = {
                href: get(link, 'url'),
                target: get(link, 'target'),
                onClick: toggleModal,
              }
              return (
                <Item
                  key={`link${i}`}
                  {...linksProps}
                  active={active.toString()}
                  len={list.length}
                >
                  {active && (
                    <Text size="xxs" bold center color="yellow" uppercase>
                      {hereLabel}
                    </Text>
                  )}
                  <Text.h5 center color={active ? '#fff' : 'blue'}>
                    {name}
                  </Text.h5>
                  <Text.h4 center color={active ? '#fff' : 'blue'}>
                    {location}
                  </Text.h4>
                </Item>
              )
            })}
          </List>
          <Button onClick={toggleModal}>{backLabel}</Button>
        </Content>
      </Modal>
    </>
  )
}

PropertiesSmall.propTypes = {
  backLabel: PropTypes.string,
  label: PropTypes.string,
  hereLabel: PropTypes.string,
  list: PropTypes.array,
}

export default PropertiesSmall

const AddressLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    background-color: #fff;
    padding: ${theme.space(2)};
    border-radius: ${theme.layout.borderRadius};

    svg {
      margin-right: ${theme.space(0.25)};
    }

    cursor: pointer;
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;

    background-color: #fff;
    padding: 0 ${theme.space(1)} ${theme.space(1)};

    > button {
      margin: 0 auto;
      width: 100%;
      max-height: 42px;
      flex-shrink: 0;
    }
  `};
`

const Item = styled(Link)`
  ${({ active, len, theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    min-height: ${rem(60)};
    max-height: ${rem(160)};
    padding: 0 ${theme.space(2)};
    margin: ${theme.space(1)} 0;
    border: 1px solid ${theme.color.softBlue};
    border-radius: ${theme.layout.borderRadius};

    &:first-child {
      min-height: ${rem(90)};
    }

    ${active === 'true' &&
      css`
        ${theme.layout.bgGradient};

        > div {
          margin-bottom: ${theme.space(1)};
        }
      `};
  `};
`

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`
