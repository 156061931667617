import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { DownArrow } from '../../common/Icons'
import Link from '../../common/Link'
import LinkContainer, {
  LinkContainerItem,
  itemMotionProps,
} from './LinkContainer'
import ReactHtmlParser from 'react-html-parser'
import Text from '../../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import { transformParagraphToSpan } from '../../../utils/parserTransforms'
import { useAppContext } from '../../../context/AppContext'
import linkResolver from '../../../utils/linkResolver'
import { ICON_ATLAS, iconCss } from '../../common/IconAtlas'
import { ALL_ICON_TYPES } from '../../../../lib/constants'
import betRivers from '../../../assets/bet-rivers.png'

const TopNavBar = ({ topNavBar, properties }) => {
  const [showLinks, setLinks] = useState(false)
  const toggleShowLinks = () => setLinks(!showLinks)
  const handleShowLinks = () => setLinks(true)
  const handleHideLinks = () => setLinks(false)
  const { isXLarge, focusStyleOn } = useAppContext()
  const propertiesText = get(properties, 'label')
  const hereLabel = get(properties, 'here_label')
  const links = get(topNavBar, 'links') || []
  const left = links.filter(item => item.position === 'left')
  const right = links.filter(item => item.position === 'right')
  const propertyItems = get(properties, 'properties')
  const rushRewardsLink = {
    href: linkResolver(topNavBar, 'rush_rewards_link'),
    target: get(topNavBar, 'rush_rewards_link.target'),
  }

  useEffect(() => {
    if (!isXLarge && showLinks) {
      setLinks(false)
    }
  }, [showLinks, isXLarge])

  const RRIcon = ICON_ATLAS[ALL_ICON_TYPES.ICON_RUSHREWARDS]
  const BetRiversIcon = ICON_ATLAS[ALL_ICON_TYPES.ICON_BETRIVERS]

  return (
    <Container>
      <Content>
        <Row>
          {propertiesText && (
            <PropertiesButton
              showFocus={focusStyleOn}
              aria-label={propertiesText}
              active={showLinks}
              onClick={toggleShowLinks}
              onMouseEnter={handleShowLinks}
              onMouseLeave={handleHideLinks}
              tabIndex={0}
            >
              <Text
                white={!showLinks}
                bold
                size="xxs"
                uppercase
                nowrap
                spacing={0.5}
              >
                {propertiesText}
                <DownArrow fill={showLinks ? 'blue' : '#fff'} />
              </Text>
            </PropertiesButton>
          )}
          {left &&
            left.map((item, i) => {
              const text = get(item, 'label.html')
              const link = {
                href: linkResolver(item, 'link'),
                target: get(item, 'link.target'),
              }
              return text ? (
                <Link key={`left${i}`} {...link}>
                  <Text white bold size="xxs" uppercase nowrap spacing={0.5}>
                    {ReactHtmlParser(text, {
                      transform: transformParagraphToSpan,
                    })}
                  </Text>
                </Link>
              ) : null
            })}
        </Row>
        <Row isRight>
          {right &&
            right.map((item, i) => {
              const text = get(item, 'label.html')
              const image = get(item, 'icon.url')
              const Wrapper = image ? Flex : Fragment
              const link = {
                href: linkResolver(item, 'link'),
                target: get(item, 'link.target'),
              }

              if (link.href.indexOf('betrivers.com') > -1) {
                //this is a bet rivers link, let's pull in the icon
                return (
                  <Link key={`right${i}`} {...link}>
                    <Text
                      medium
                      size="xxs"
                      xl={image ? 'xxs' : 'xs'}
                      uppercase
                      nowrap
                      color={image ? 'yellow' : '#fff'}
                      spacing={1.4}
                    >
                      <Wrapper>
                        {betRivers ? (
                          <img
                            src={betRivers}
                            alt="logo"
                            width={170}
                            height={50}
                            style={{ height: '50px', width: '170px' }}
                          />
                        ) : (
                          ReactHtmlParser(text, {
                            transform: transformParagraphToSpan,
                          })
                        )}
                      </Wrapper>
                    </Text>
                  </Link>
                )
              }
              return text ? (
                <Link key={`right${i}`} {...link}>
                  <Text
                    medium
                    size="xxs"
                    xl={image ? 'xxs' : 'xs'}
                    uppercase
                    nowrap
                    color={image ? 'yellow' : '#fff'}
                    spacing={1.4}
                  >
                    <Wrapper>
                      {image && <img src={image} alt="logo" />}
                      {ReactHtmlParser(text, {
                        transform: transformParagraphToSpan,
                      })}
                    </Wrapper>
                  </Text>
                </Link>
              ) : null
            })}
          <Link {...rushRewardsLink}>
            <Text medium size="xxs" xl="xxs" uppercase nowrap color="yellow">
              <Flex>
                <RRIcon className="use-fill-only" />
                <span>
                  <strong>Rush</strong> Rewards
                </span>
              </Flex>
            </Text>
          </Link>
        </Row>
      </Content>
      {propertyItems && (
        <div onMouseEnter={handleShowLinks} onMouseLeave={handleHideLinks}>
          <LinkContainer show={showLinks} y={50}>
            <Grid columns={properties.properties.length}>
              {propertyItems.map(({ name, link, location, ...props }, i) => {
                const active = i === 0
                const linksProps = {
                  href: linkResolver({ link }, 'link'),
                  target: get(link, 'target'),
                }
                return (
                  <LinkContainerItem
                    active={active}
                    key={`link${i}`}
                    index={i}
                    variants={itemMotionProps}
                    showFocus={focusStyleOn}
                    whitehover
                  >
                    <Link {...linksProps} tabIndex={active ? -1 : 0}>
                      {active && (
                        <HereLabel
                          size="xxs"
                          bold
                          center
                          color="yellow"
                          uppercase
                        >
                          {hereLabel}
                        </HereLabel>
                      )}
                      <Text.h5 center color={active ? '#fff' : 'blue'}>
                        {name}
                      </Text.h5>
                      <Text.h4
                        center
                        color={active ? '#fff' : 'blue'}
                        css={{ marginTop: rem(4) }}
                      >
                        {location}
                      </Text.h4>
                    </Link>
                  </LinkContainerItem>
                )
              })}
            </Grid>
          </LinkContainer>
        </div>
      )}
    </Container>
  )
}

TopNavBar.propTypes = {
  properties: PropTypes.object,
  topNavBar: PropTypes.object,
}

export default TopNavBar

const Grid = styled.div`
  ${({ theme, columns }) => css`
    grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
    display: grid;
    grid-gap: ${theme.space(3)} ${theme.space(3)};

    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: none;
    justify-content: center;

    width: 100%;
    height: ${rem(70)};

    padding-left: ${rem(45)};
    padding-right: ${rem(45)};

    background-color: ${theme.color.secondaryBlue};

    ${theme.media.xl`
      display: flex;
    `};
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const Row = styled.div`
  ${({ isRight, theme }) => css`
    display: flex;
    align-items: center;
    a {
      ${theme.mixin.opacityHover};

      &:not(:last-child) {
  ${isRight &&
    css`
      transform: translateY(-1px);
    `};
      }

    }

    a, button {
      margin-${isRight ? 'left' : 'right'}: ${theme.space(4)};
    }

    transform: translateY(1px);
  `};
`

const Flex = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      transform: translateY(1px);
    }

    img,
    svg {
      margin-right: ${theme.space(0.5)};
      height: ${rem(26)};
      width: ${rem(26)};
    }
    ${iconCss(theme.color.yellow)}
  `};
`

const PropertiesButton = styled.button`
  ${({ active, theme }) => css`
    position: relative;
    cursor: pointer;
    margin-left: -${rem(7)};
    transform: translate(-1px);

    svg {
      display: inline-block;
      width: 9px !important;
      height: auto;
      object-fit: scale-down;
      margin-left: ${theme.space(0.5)};
    }

    ${theme.mixin.afterFocus};

    &:before {
      content: '';
      position: absolute;
      top: ${rem(-10)};
      left: ${rem(-5)};
      width: calc(100% + ${rem(10)});
      height: ${rem(46)};
      background-color: ${active ? theme.color.softBlue : 'transparent'};
      transition: background-color 0.3s ease-out;
      border-top-left-radius: ${rem(5)};
      border-top-right-radius: ${rem(5)};
    }
  `};
`
const HereLabel = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(1)};
  `};
`
