import { useState, useEffect } from 'react'
import keyCodes from '../constants/keyCodes'

const isClient = () => typeof window !== 'undefined'

const KEY_CODES = [
  keyCodes.TAB,
  keyCodes.UP,
  keyCodes.DOWN,
  keyCodes.LEFT,
  keyCodes.RIGHT,
  keyCodes.SPACE,
  keyCodes.END,
  keyCodes.ESC,
]

export default function() {
  const [focusStyleOn, setFocusStyleOn] = useState(false)

  useEffect(() => {
    if (!isClient()) return false

    const onWindowKeyDown = e => {
      if (!focusStyleOn && KEY_CODES.includes(e.keyCode)) {
        setFocusStyleOn(true)
      }
    }

    window.addEventListener('keydown', onWindowKeyDown, false)
    return () => window.removeEventListener('keydown', onWindowKeyDown)
  }, [focusStyleOn])

  return focusStyleOn
}
