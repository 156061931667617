import React from 'react'
import PropTypes from 'prop-types'

import Text from '../../common/Text'
import Link from '../../common/Link'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get, isNull } from 'lodash'
import linkResolver from '../../../utils/linkResolver'

const NavItem = ({
  id,
  activeNavId,
  handleMouseEnter,
  handleMouseLeave,
  ...props
}) => {
  const label = get(props, 'primary.label')
  const navLink = {
    href: linkResolver(props, 'primary.link'),
    target: get(props, 'primary.link.target'),
  }
  const navItemActive = id === activeNavId
  return (
    <Link {...navLink}>
      <Container
        active={navItemActive}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Text
          color={navItemActive || isNull(activeNavId) ? 'blue' : 'navTextGrey'}
          medium
          center
          size="xs"
          xl="sm"
          uppercase
          nowrap
          spacing={1.5}
        >
          {label}
        </Text>
      </Container>
    </Link>
  )
}

NavItem.propTypes = {
  id: PropTypes.string,
  activeNavId: PropTypes.string,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
}

export default NavItem

const Container = styled.div`
  ${({ active, theme }) => css`
    position: relative;
    padding: 0 1.5vw;
    cursor: pointer;

    > div {
      transition: color 0.3s ease-out;
    }

    ${theme.media.xxl`
        padding: 0 ${theme.space(3)};
    `};

    ${active &&
      css`
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: ${rem(57)};
        }
      `};
  `}
`
