import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { motion, AnimatePresence } from 'framer-motion'

import styled, { css } from 'styled-components'
import { rem } from 'polished'

const MASK_HEIGHT = 220

const motionProps = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
  },
  exit: { opacity: 0 },
}

export const itemMotionProps = {
  initial: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -20 },
  exit: { opacity: 0, y: -20 },
}

export const maskMotionProps = {
  visible: { opacity: 1, height: MASK_HEIGHT },
  hidden: { opacity: 0, height: 0 },
}

export const LinkContainerItem = ({ children, index, ...props }) => {
  const el = useRef(null)

  useEffect(() => {
    if (index === 0) {
      const target = el && el.current.querySelector('a')
      if (target) target.focus()
    }
  }, [index])

  return (
    <Item ref={el} {...props}>
      {children}
    </Item>
  )
}

LinkContainerItem.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  children: PropTypes.node,
}

const LinkContainer = ({ uId, children, columns, show, sticky, y }) => {
  return (
    <Mask
      y={sticky ? y + 10 : y}
      initial={{ opacity: 0, height: 0 }}
      animate={show ? 'visible' : 'hidden'}
      variants={maskMotionProps}
      transition={{
        type: 'tween',
        duration: 0.3,
        ease: show ? 'easeOut' : 'easeIn',
      }}
    >
      <AnimatePresence>
        {show && (
          <Container
            key="links"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'tween',
              duration: show ? 0.3 : 0.4,
              ease: show ? 'easeOut' : 'easeIn',
            }}
          >
            <Content
              key={uId}
              columns={columns}
              initial="hidden"
              animate="visible"
              variants={motionProps}
            >
              {children}
            </Content>
          </Container>
        )}
      </AnimatePresence>
    </Mask>
  )
}

LinkContainer.propTypes = {
  columns: PropTypes.number,
  children: PropTypes.node,
  show: PropTypes.bool,
  y: PropTypes.number,
  uId: PropTypes.string,
  sticky: PropTypes.bool,
}

LinkContainer.defaultProps = {
  columns: 4,
  y: 0,
}

export default LinkContainer

const Mask = styled(motion.div)`
  ${({ y }) => css`
    position: absolute;
    top: ${rem(y)};
    left: 0;
    width: 100%;
    height: ${rem(MASK_HEIGHT)};

    overflow: hidden;
    z-index: 9999;
    pointer-events: none;

    box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.2);
  `};
`

const Container = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    padding: 0 ${theme.space(4)};

    background-color: ${theme.color.softBlue};
    border-radius: 0 0 5px 5px;

    pointer-events: all;
  `};
`

const Content = styled(motion.div)`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

export const Item = styled(motion.li)`
  ${({ active, extLink, hasHeader, isBlue, columns, theme, whitehover }) => css`
    position: relative;
    list-style: none;
    width: 100%;
    height: ${rem(columns > 4 ? 70 : 160)};
    border: 1px solid ${theme.color.secondaryLightBlue};
    border-radius: ${theme.layout.borderRadius};
    transition: background 0.5s ease-out;
    overflow: hidden;

    ${extLink &&
      css`
        h5 {
          max-width: calc(100% - $rem(80));
        }
      `};

    svg {
      position: absolute;
      top: 50%;
      right: ${theme.space(2)};
      transform: translateY(-50%);
    }

    > a {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 ${theme.space(1)};

      ${theme.mixin.afterFocus};

      ${hasHeader
        ? css`
            padding-top: ${rem(45)};
            overflow: hidden;

            ${theme.media.xxl`
          padding-top: ${rem(55)};
        `};
          `
        : css`
            justify-content: center;
          `};

      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);

      ${whitehover &&
        css`
          background-color: #fff;
        `};

      > span {
        opacity: 1;
      }
    }

    ${active &&
      css`
        pointer-events: none;
      `};

    ${(active || isBlue) &&
      css`
        ${theme.layout.bgGradient};

        > div {
          margin-bottom: ${theme.space(1)};
        }
      `};
  `};
`

export const HoverBg = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
