import styled, { css } from 'styled-components'
import { NODE_ENV } from '../../../lib/constants'
const IS_DEV = NODE_ENV === 'development'

const MediaQueryDebug = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;

    ${!IS_DEV &&
      css`
        display: none;
      `};

    :after {
      font-size: 1em;
      line-height: 1em;
      content: 'XS';
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 0 4px;

      ${theme.media.xs`content: 'XS'`};
      ${theme.media.sm`content: 'SM'`};
      ${theme.media.md`content: 'MD'`};
      ${theme.media.lg`content: 'LG'`};
      ${theme.media.xl`content: 'XL'`};
      ${theme.media.xxl`content: 'XXL'`};
    }
  `};
`

export default MediaQueryDebug
